import { Swiper, Pagination, Navigation } from 'swiper';
import 'swiper/css';

let advantageSwiper: any;

function init(container: HTMLElement | Document = document) {
    const swiperElement = container.querySelector<HTMLElement>('.js-advantages-swiper');

    if (swiperElement) {
        advantageSwiper = new Swiper(swiperElement, {
            grabCursor: true,
            breakpoints: {
                1920: {
                    speed: 900,
                    slidesPerView: 1.09,
                    spaceBetween: 16,
                },
                1280: {
                    speed: 700,
                    slidesPerView: 1.09,
                    spaceBetween: 8,
                },
                768: {
                    speed: 600,
                    slidesPerView: 1.07,
                    spaceBetween: 4,
                },
                320: {
                    speed: 600,
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
            },
            pagination: {
                el: ".swiper-pagination",
                type: 'fraction',
                formatFractionCurrent: function (number) {
                    return ('0' + number).slice(-2);
                },
                formatFractionTotal: function (number) {
                    return ('0' + number).slice(-2);
                },
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' +
                   ' / ' +
                   '<span class="' + totalClass + '"></span>';
                }
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            modules: [Pagination, Navigation]
        });
    }
}

function destroy() {
    advantageSwiper.destroy();
    advantageSwiper = null;
}

export default { init, destroy };
