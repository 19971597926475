let multiSelects: NodeListOf<HTMLElement>;

function multiSelectToggle(e: Event) {
    const target = e.target as HTMLElement;
    const select = target.closest('.js-multi-select');
    if (!select) {
        multiSelects.forEach((multiSelect) => closeSelect(multiSelect));
    } else {
        if (select.classList.contains('multi-select--open')) {
            select?.classList.remove('multi-select--open');
        } else {
            multiSelects.forEach((multiSelect) => multiSelect?.classList.remove('multi-select--open'));
            select?.classList.add('multi-select--open');
        }
    }
}

function closeSelect(select: HTMLElement) {
    select?.classList.remove('multi-select--open');
}

export function setPlaceholder(input: HTMLElement) {
    const container = input.closest('.js-multi-select');
    if (!container) return;

    const placeholder = container.querySelector<HTMLElement>('.js-multi-select__placeholder');
    if (placeholder) {
        placeholder.textContent = `${input.nextElementSibling?.textContent}`;
    }
}

function init(container: HTMLElement | Document = document) {
    multiSelects = container.querySelectorAll<HTMLElement>('.js-multi-select');

    multiSelects?.forEach((multiSelect) => {
        let multiSelectInputs: HTMLInputElement[] = [];
        multiSelectInputs = Array.from(multiSelect.querySelectorAll<HTMLInputElement>('.multi-select__input'));
        const resetCheckboxBtn = multiSelect.querySelector<HTMLElement>('.js-multi-select__reset');
        const multiSelectPlaceholder = multiSelect.querySelector<HTMLElement>('.js-multi-select__placeholder');

        function resetCheckbox() {
            multiSelectInputs?.forEach((el) => {
                el.checked = false;
            });
        }

        function placeholderSet(this: HTMLInputElement) {
            if (this.checked) {
                multiSelectPlaceholder?.classList.add('input-checked');
            }

            if (this.type === 'radio') {
                const select = this.closest<HTMLElement>('.js-multi-select');
                const options = select?.querySelectorAll<HTMLInputElement>('.multi-select__input');

                options?.forEach((option) => {
                    if (option !== this) {
                        option.checked = false;
                    }
                });
                if (select) closeSelect(select);
            }

            setTimeout(() => {
                let checkedInputsCount = 0;
                let lastcheckedInput;
                // считаем сколько активных эелментов и записываем контент последнего
                multiSelectInputs.forEach((el) => {
                    if (el.checked) {
                        checkedInputsCount++;
                        lastcheckedInput = el.nextElementSibling?.textContent;
                    }
                });

                // если активных больше 1, то значение будет (text + count)
                if (checkedInputsCount > 1 && multiSelectPlaceholder && lastcheckedInput) {
                    multiSelectPlaceholder.innerHTML =
                        lastcheckedInput +
                        ` <span class="multi-select__placeholder-count">+${checkedInputsCount - 1}</span>`;
                } else if (checkedInputsCount <= 1 && multiSelectPlaceholder && lastcheckedInput) {
                    multiSelectPlaceholder.textContent = lastcheckedInput;
                }
            }, 100);
        }

        function setOthers(this: HTMLInputElement) {
            if (multiSelect.classList.contains('js-with-other')) {
                const checkboxesToVerify = Array.from(
                    multiSelect.querySelectorAll<HTMLInputElement>(`input[type="checkbox"]`),
                );

                const emptyValueCheckbox = checkboxesToVerify.find((checkbox) => !checkbox.value.trim());
                const otherCheckboxes = checkboxesToVerify.filter((checkbox) => checkbox !== emptyValueCheckbox);

                if (emptyValueCheckbox) {
                    if (this === emptyValueCheckbox) {
                        if (this.checked) {
                            otherCheckboxes.forEach((checkbox) => {
                                checkbox.checked = false;
                            });
                        }
                    } else if (otherCheckboxes.some((checkbox) => checkbox.checked)) {
                        emptyValueCheckbox.checked = false;
                    }
                }
            }
        }

        multiSelectInputs.forEach((el) => {
            el.addEventListener('change', placeholderSet);
            el.addEventListener('change', setOthers);

            if (el.checked) {
                const placeholderSetBind = placeholderSet.bind(el);
                placeholderSetBind();
            }
        });

        resetCheckboxBtn?.addEventListener('click', resetCheckbox);
    });
    window?.addEventListener('click', multiSelectToggle);
}

const _module = { init };

export default _module;
