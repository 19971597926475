export default function createApartamentItem(el: any) {
    const item = document.createElement('li');
    item.classList.add('catalog__item', 'swiper-slide');
    item.innerHTML = `
        <a href="${el.link}" class="catalog-card">
            <div class="catalog-card__like like js-like" data-id="${el.id}" data-url="${el.url_for_likes}">
                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.2" d="M1 11L11 18L21 11L22 4.5L17.1111 0H13.2222L11 3.5L8.77778 0H4.88889L0 4.5L1 11Z" fill="#252422"/>
                </svg>
            </div>
            <div class="catalog-card__pic responsive">
                <img alt="${el.name}" src="${
        el.image
    }" class="catalog-card__image responsive-img" width="288" height="251" loading="lazy">
            </div>
            <h2 class="catalog-card__title sh3-text">${el.name}</h2>
            <ul class="catalog-card__benefits list-unstyled layout-benefits-list">
                ${el.benefits
                    .map((el: string) => {
                        return `<li class="layout-benefits-item button-text layout-text">${el}</li>`;
                    })
                    .join('')}
            </ul>
            <div class="catalog-card__info">
                <div class="catalog-card__info-item">
                    <p class="layout-caption text-cap">Этаж:</p>
                    <p class="layout-text button-text">${el.floor}</p>
                </div>
                <div class="catalog-card__info-item">
                    <p class="layout-caption text-cap">Номер кв:</p>
                    <p class="layout-text button-text">${el.number}</p>
                </div>
                <div class="catalog-card__info-item">
                    <p class="layout-caption text-cap">Ключи до:</p>
                    <p class="layout-text button-text">${el.term}</p>
                </div>
            </div>
            <div class="catalog-card__price-block">
                <div class="catalog-card__price sh2-text">${el.price}</div>
                <div class="catalog-card__price-min text-cap">${el.price_per_meter}</div>
            </div>
        </a>
    `;
    return item;
}
