/* eslint-disable max-lines */
import createBankItem from '../utils/createBankItem';
import createApartamentItem from '../utils/createApartamentItem';
import similarSlider from './similar-slider';
import promptModal from './prompt-modal';
import likes from './likes-card';
import axios from 'axios';

// разделить число на тысячные
function prettify(num: number) {
    const n = num.toString();
    return n.replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');
}

// убрать из числа пробелы от imask
function numberTrim(num: string) {
    return +num.replace(/ /g, '');
}

// окончание у предложений
function getNoun(number: number, one: string, two: string, five: string) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
}

function createMessage() {
    const message = document.createElement('li');
    message.classList.add('calculator-banks__message');
    message.textContent = `По данным параметрам ничего не найдено`;
    return message;
}

function createSliderContainer() {
    const sliderContainer = document.createElement('div');
    sliderContainer.classList.add('catalog-similar', 'js-similar-slider', 'swiper');
    const sliderWrapper = document.createElement('ul');
    sliderWrapper.classList.add('swiper-wrapper', 'list-unstyled');
    sliderContainer.appendChild(sliderWrapper);
    return sliderContainer;
}

let _this: Calculator;

class Calculator {
    container: HTMLElement;
    totalCost: number;
    inputs: NodeListOf<HTMLInputElement>;
    typeOfMortgageInput: HTMLInputElement[];
    priceMortgageInput: HTMLInputElement | null;
    initialFeeInput: HTMLInputElement | null;
    mortgageTermInput: HTMLInputElement | null;
    totalCostInner: HTMLElement | null;
    initialFeePercentInner: HTMLElement | null;
    banksContainer: HTMLElement | null;
    banksMoreContainer: HTMLElement | null;
    apartamentsContainer: HTMLElement | null;
    linkCatalogContainer: HTMLLinkElement | null;
    cardMortgageAmountInnerArr: NodeListOf<HTMLElement>;
    linkCatalogValue: string | undefined;
    priceMortgageInputValue: number;
    initialFeeInputValue: number;
    initialFeePercent: number;
    typeOfMortgageValue: string | undefined;
    appartamentsJson: Array<any> = [];

    constructor(container: HTMLElement) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        _this = this;
        this.container = container;
        this.totalCost = 0;
        this.inputs = this.container.querySelectorAll('.js-calculator-input');
        // eslint-disable-next-line max-len
        this.typeOfMortgageInput = Array.from(
            this.container.querySelectorAll<HTMLInputElement>('.js-mortgage-type-input'),
        );
        this.priceMortgageInput = this.container.querySelector<HTMLInputElement>('.js-mortgage-price-input');
        this.initialFeeInput = this.container.querySelector<HTMLInputElement>('.js-initial-fee-input');
        this.priceMortgageInputValue = numberTrim(this.priceMortgageInput ? this.priceMortgageInput.value : '0');
        this.initialFeeInputValue = numberTrim(this.initialFeeInput ? this.initialFeeInput.value : '0');
        this.typeOfMortgageValue = this.typeOfMortgageInput.find((input) => input.checked)?.value;
        this.mortgageTermInput = this.container.querySelector<HTMLInputElement>('.js-mortgage-term-input');
        this.initialFeePercentInner = this.container.querySelector<HTMLInputElement>('.js-initial-fee-percent');
        this.initialFeePercent = 0;
        this.totalCostInner = this.container.querySelector<HTMLElement>('.js-mortgage-total-cost');
        this.banksContainer = this.container.querySelector<HTMLElement>('.js-banks-container');
        this.banksMoreContainer = this.container.querySelector<HTMLElement>('.js-banks-count');
        this.apartamentsContainer = this.container.querySelector<HTMLElement>('.js-calculator-apartaments');
        this.linkCatalogContainer = this.container.querySelector<HTMLLinkElement>('.js-calculator-link');
        this.cardMortgageAmountInnerArr = document.querySelectorAll<HTMLElement>('.js-card-mortgage-amount');
        this.linkCatalogValue = this.linkCatalogContainer?.href;
        this.appartamentsJson;
        this._init();
        this.setFirstDatas();
    }

    _init() {
        this.inputs.forEach((input) => input.addEventListener('change', this.changeInputHandler));
    }

    setFirstDatas = () => {
        _this.typeOfMortgageValue = this.typeOfMortgageInput.find((input) => input.checked)?.value;
        _this.calculateTotalCost();
        _this.calculateInitialFeePercent();
        _this.setBanks();
        _this.setApartamentsFetch();
    };

    async changeInputHandler() {
        const trigger = this as unknown as HTMLElement;
        // если изменился вид ипотеки, то сначала меняем значения в калькуляторе
        if (trigger.classList.contains('js-mortgage-type-input')) {
            const minInitialFeeForThisType = trigger.dataset.minInitialFee;
            const maxTermForThisType = trigger.dataset.maxTerm;

            // сравниваем срок кредита
            if (
                _this.mortgageTermInput !== undefined &&
                _this.mortgageTermInput !== null &&
                maxTermForThisType &&
                +_this.mortgageTermInput.value > +maxTermForThisType
            ) {
                _this.mortgageTermInput.value = maxTermForThisType;
            }

            // сравниваем первоначальный взнос
            if (
                _this.priceMortgageInput &&
                _this.initialFeeInput &&
                minInitialFeeForThisType &&
                _this.initialFeePercent !== undefined &&
                _this.initialFeePercent < +minInitialFeeForThisType
            ) {
                _this.initialFeeInput.value = `${prettify(
                    numberTrim(_this.priceMortgageInput.value) * (+minInitialFeeForThisType / 100),
                )}`;
            }

            setTimeout(() => {
                _this.setFirstDatas();
            }, 100);
        } else {
            _this.setFirstDatas();
        }
    }

    // расчитываем стоимость кредита и записываем значение в контейнер
    calculateTotalCost() {
        if (!this.totalCostInner || !this.priceMortgageInput || !this.initialFeeInput) return;
        this.priceMortgageInputValue = numberTrim(this.priceMortgageInput.value);
        this.initialFeeInputValue = numberTrim(this.initialFeeInput.value);
        const result = this.priceMortgageInputValue - this.initialFeeInputValue;
        this.totalCost = result;
        this.totalCostInner.innerHTML = `${prettify(result)}`;
    }

    // процент первоначального взноса - расчитываем и записываем в инпут
    calculateInitialFeePercent() {
        if (this.initialFeePercentInner) {
            this.initialFeePercent = Math.round((this.initialFeeInputValue / this.priceMortgageInputValue) * 100);
            this.initialFeePercentInner.innerHTML = `${this.initialFeePercent}`;
        }
    }

    // расчитываем месячный платеж
    calculateMonthlyPayment(rate: Array<string>) {
        // сумма кредита *
        // (процентная ставка \ 12) * (1 + процентная ставка \ 12 \ 100)^(период кредитования в мес) /
        // (1 + процентная ставка \ 12 \ 100)^(период кредитования в мес) - 1
        const result = [];
        if (!this.mortgageTermInput) return;
        for (let i = 0; i < rate.length; i++) {
            const x = Math.pow(1 + +rate[i] / 12 / 100, +this.mortgageTermInput.value * 12);
            const resultTop = (+rate[i] / 12 / 100) * x;
            const resultBottom = x - 1;
            result.push(prettify(Math.round((this.totalCost * resultTop) / resultBottom)));
        }
        return result; //ежемесячныая плата

        // второй вариант формулы (с их сайта) - пока оставить
        // const result = [];
        // const sumCredit = 6291870 * (1 - 30 / 100);
        // const monthlyRate = 3.99 / 12 / 100;
        // const sumRate = Math.pow(1 + monthlyRate, 30 * 12);
        // const monthlyPayment = Math.round((sumCredit * monthlyRate * sumRate) / (sumRate - 1));
        // result.push(prettify(monthlyPayment));

        // return result; //ежемесячныая плата
    }

    // фильтруем и добавляем на страницу предложения банков
    setBanks() {
        if (!this.banksContainer) return;

        this.banksContainer.classList.add('is-loading');

        setTimeout(() => {
            if (!this.banksContainer) return;
            this.banksContainer.innerHTML = '';
            let banksCount = 0;
            const banksJson = window.banksJson.sort((x: any, y: any) => x.rate[0] - y.rate[0]);
            let isSetMinMonthlyPayment = false; // установлен ли минимальный платеж (для деальной страницы)
            for (const key in banksJson) {
                const monthlyPaument = this.calculateMonthlyPayment(banksJson[key]['rate']);

                // проверяем срок ипотеки (либо до, либо от и до)
                if (banksJson[key]['year'].length == 1) {
                    if (this.mortgageTermInput!.value > banksJson[key]['year'][0]) {
                        continue;
                    }
                } else if (banksJson[key]['year'].length == 2) {
                    if (
                        this.mortgageTermInput!.value < banksJson[key]['year'][0] ||
                        this.mortgageTermInput!.value > banksJson[key]['year'][1]
                    ) {
                        continue;
                    }
                }

                // фильтруем банки (взнос ОТ, вид ипотеки, максимальная сумма кредита)
                if (
                    !banksJson[key]['type'].includes(this.typeOfMortgageValue) ||
                    !this.mortgageTermInput?.value ||
                    this.initialFeePercent < banksJson[key]['initialFee'] ||
                    this.totalCost > banksJson[key]['maxAmount']
                ) {
                    continue;
                }
                banksCount++;

                // устанавливаем минимальный платеж на детальной странице
                if (!isSetMinMonthlyPayment && monthlyPaument) {
                    isSetMinMonthlyPayment = true;

                    this.cardMortgageAmountInnerArr.forEach((el) => {
                        el.innerHTML = `${monthlyPaument[0]}`;
                    });
                }

                // параметры конкретного банка (предложения)
                const bankInfo = {
                    logo: banksJson[key]['logo'],
                    name: banksJson[key]['name'],
                    rate: banksJson[key]['rate'],
                    first_payment_term: banksJson[key]['first_payment_term'],
                    year: banksJson[key]['year'].length == 1 ? banksJson[key]['year'][0] : banksJson[key]['year'][1],
                    initialFee: banksJson[key]['initialFee'],
                    monthyPayment: monthlyPaument,
                    message: banksJson[key]['message'],
                };
                // создаем банк на странице
                const bankItem = createBankItem(bankInfo);
                this.banksContainer.appendChild(bankItem);
            }

            // проверяем кол-во, чтоб скрыть/показать кнопку аккордиона или сообщение
            if (banksCount === 0) {
                const message = createMessage();
                this.banksContainer.appendChild(message);
            } else if (banksCount <= 4) {
                this.banksContainer.classList.add('is-show');
            } else {
                this.banksContainer.classList.remove('is-show');
                if (this.banksMoreContainer) {
                    this.banksMoreContainer.innerHTML = `
                        ${banksCount - 4 + getNoun(banksCount - 4, ' предложение', ' предложения', ' предложений')}
                    `;
                }
            }

            promptModal.init();

            setTimeout(() => {
                this.banksContainer?.classList.remove('is-loading');
            }, 600);
        }, 400);
    }

    // добавляем на страницу подходящие квартиры
    setApartaments() {
        if (!this.apartamentsContainer) return;
        this.apartamentsContainer.innerHTML = '';

        // контейнер слайдера
        const sliderContainer = createSliderContainer();
        const sliderWrapper = sliderContainer.querySelector('.swiper-wrapper');
        this.apartamentsContainer.appendChild(sliderContainer);

        // добавляем на страницу
        for (let i = 0; i < this.appartamentsJson.length; i++) {
            // параметры конкретного банка (предложения)
            const apartamentInfo = {
                id: this.appartamentsJson[i]['id'],
                link: this.appartamentsJson[i]['link'],
                url_for_likes: this.appartamentsJson[i]['url_for_likes'],
                image: this.appartamentsJson[i]['image'],
                name: this.appartamentsJson[i]['name'],
                benefits: this.appartamentsJson[i]['benefits'],
                floor: this.appartamentsJson[i]['floor'],
                number: this.appartamentsJson[i]['number'],
                term: this.appartamentsJson[i]['term'],
                price: this.appartamentsJson[i]['price'],
                price_per_meter: this.appartamentsJson[i]['price_per_meter'],
            };

            // создаем квартиру на странице
            const apartamentItem = createApartamentItem(apartamentInfo);
            sliderWrapper?.appendChild(apartamentItem);
        }

        setTimeout(() => {
            if (!this.appartamentsJson.length && this.apartamentsContainer) {
                if (this.apartamentsContainer.parentElement) {
                    this.apartamentsContainer.parentElement.style.display = 'none';
                }
            } else if (this.apartamentsContainer) {
                similarSlider.init(this.apartamentsContainer);
                likes.init(this.apartamentsContainer);

                if (this.apartamentsContainer.parentElement) {
                    this.apartamentsContainer.parentElement.style.display = '';
                }
            }
        }, 600);
    }

    // запрос на новые квартиры
    setApartamentsFetch() {
        if (!this.apartamentsContainer) return;
        const url = this.apartamentsContainer.dataset.url;

        if (url) {
            this.apartamentsContainer.classList.add('is-loading');
            axios
                .post(url, {
                    price: this.priceMortgageInputValue,
                })
                .then((responce) => {
                    if (responce.data.data.appartments) {
                        this.appartamentsJson = responce.data.data.appartments;
                        this.setApartaments();
                    }

                    if (responce.data.data.catalog_link && this.linkCatalogContainer) {
                        this.linkCatalogContainer.innerHTML = responce.data.data.catalog_link;
                    }
                })
                .catch((err) => {
                    if (err.name !== 'AbortError') {
                        throw err;
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.apartamentsContainer?.classList.remove('is-loading');
                    }, 800);
                });
        }
    }
}

function init(container: HTMLElement | Document = document) {
    const calcualtorContainers = container.querySelectorAll<HTMLElement>('.js-mortgage-calculator');
    calcualtorContainers.forEach((calculator) => {
        new Calculator(calculator);
    });
}

const _module = { init };

export default _module;
