import delegate from 'delegate';

export function removeInputs(container: HTMLElement | Document = document) {
    const inputs = container.querySelectorAll<HTMLElement>('.js-calculator-form-input');
    inputs.forEach(input => input.remove());
}

function setHiddenInput (name: string, value: string, form: HTMLElement | null | undefined) {
    const input = document.createElement('input');
    input.classList.add('js-calculator-form-input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;

    if (form) form.prepend(input);
}

function formTriggerHandler(this: HTMLElement, e: any) {
    const target = e.delegateTarget as HTMLElement;
    const parent = target.closest('.js-mortgage-calculator');
    if (!parent) return;

    const name = target.getAttribute('data-lit-popup-open');
    const popup = document.querySelector(`[data-lit-popup="${name}"]`);
    const formPopup = popup?.querySelector<HTMLElement>('.js-form');

    const form = parent.querySelector('.js-calculator-form');
    const inputs = form?.querySelectorAll<HTMLInputElement>('.js-calculator-input');

    inputs?.forEach(input => {
        if (input.type === 'radio') {
            if (input.checked) {
                setHiddenInput(input.name, input.value, formPopup);
            }
        } else {
            setHiddenInput(input.name, input.value, formPopup);
        }
    })
}

function init() {
    delegate(document, '.js-credit-form-trigger', 'click', formTriggerHandler);
}

const _module = { init };

export default _module;

