import Swiper, { Pagination, Navigation } from 'swiper';
import 'swiper/css';

let housingSwipers: Array<any> | null = [];

function init(container: HTMLElement | Document = document) {
    const subSwiperElements = container.querySelectorAll<any>('.js-housing-swiper');

    if (subSwiperElements.length) {
        subSwiperElements.forEach((swiper: any) => {
            housingSwipers?.push(
                new Swiper(swiper, {
                    direction: 'vertical',
                    speed: 600,
                    preloadImages: true,
                    pagination: {
                        el: ".swiper-pagination",
                        type: 'fraction',
                        formatFractionCurrent: function (number) {
                            return ('0' + number).slice(-2);
                        },
                        formatFractionTotal: function (number) {
                            return ('0' + number).slice(-2);
                        },
                        renderFraction: function (currentClass, totalClass) {
                            return '<span class="' + currentClass + '"></span>' +
                           ' / ' +
                           '<span class="' + totalClass + '"></span>';
                        }
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    modules: [Pagination, Navigation]
                })
            )
        })
    }
}

function destroy() {
    housingSwipers?.forEach(swiper => {
        swiper.destroy()
    })
    housingSwipers = [];
}

export default { init, destroy };
