import gsap from 'gsap';

let tabTriggers: HTMLElement[] = [];

function onClick(this: HTMLElement) {
    if (this.classList.contains('is-active')) return;
    const container = this.closest('.js-tabs-container');
    const tabTriggers = Array.from(container?.querySelectorAll<HTMLElement>('[data-tabs-trigger]'));
    const tabs = Array.from(container?.querySelectorAll('[data-tabs-content]'));
    const value = this.dataset.tabsTrigger;
    const activeTabs = container?.querySelectorAll(`[data-tabs-content="${value}"]`);

    const activeTab = container?.querySelector<HTMLElement>('[data-tabs-trigger].is-active');
    const indexLastTab = activeTab ? tabTriggers.indexOf(activeTab) : -1;
    const indexActiveTab = tabTriggers.indexOf(this);

    tabs?.forEach((tab) => {
        if (!tab.classList.contains('js-tabs-item')) {
            tab.classList.remove('is-active');
        }
    });

    activeTabs?.forEach((el) => {
        if (el.classList.contains('js-tabs-item') && !el.classList.contains('js-tabs-item--slide')) {
            const wrapper = el.closest('.js-tabs-list');

            const tl = gsap.timeline();
            tl.fromTo(
                wrapper,
                {
                    translateY: '0px',
                    opacity: 1,
                },
                {
                    translateY: '30px',
                    opacity: 0,
                    duration: 0.5,

                    onComplete: () => {
                        tabs?.forEach((tab) => {
                            if (tab.classList.contains('js-tabs-item')) {
                                tab.classList.remove('is-active');
                            }
                        });
                        el.classList.add('is-active');
                    },
                },
            );

            tl.to(wrapper, {
                translateY: '0px',
                opacity: 1,
                duration: 0.5,
                onComplete: () => {
                    tl.kill();
                },
            });
        } else if (el.classList.contains('js-tabs-item') && el.classList.contains('js-tabs-item--slide')) {
            const lastTab = tabs[indexLastTab];
            const activeTab = tabs[indexActiveTab];

            tabTriggers.forEach((tab) => (tab.style.pointerEvents = 'none'));

            const tl = gsap.timeline({
                duration: 0.5,
            });
            if (indexActiveTab > indexLastTab) {
                tl.to(
                    lastTab,
                    {
                        yPercent: 5,
                        alpha: 0,
                        onComplete: () => {
                            lastTab.classList.remove('is-active');
                            tabTriggers.forEach((tab) => (tab.style.pointerEvents = ''));
                        },
                    },
                    0,
                ).fromTo(
                    activeTab,
                    {
                        yPercent: 100,
                    },
                    {
                        yPercent: 0,
                        alpha: 1,
                        onComplete: () => {
                            activeTab.classList.add('is-active');
                        },
                    },
                    0,
                );
            } else {
                tl.to(
                    lastTab,
                    {
                        yPercent: 100,
                        onComplete: () => {
                            lastTab.classList.remove('is-active');
                            tabTriggers.forEach((tab) => (tab.style.pointerEvents = ''));
                        },
                    },
                    0,
                )
                    .to(
                        lastTab,
                        {
                            alpha: 0,
                            duration: 0.3,
                            delay: 0.2,
                        },
                        0,
                    )
                    .fromTo(
                        activeTab,
                        {
                            yPercent: 5,
                            alpha: 0,
                        },
                        {
                            yPercent: 0,
                            alpha: 1,
                            onComplete: () => {
                                activeTab.classList.add('is-active');
                            },
                        },
                        0,
                    );
            }
        } else {
            el.classList.add('is-active');
        }
    });

    tabTriggers?.forEach((tab) => tab.classList.remove('is-active'));
    this.classList.add('is-active');
}

function init(container: HTMLElement | Document = document) {
    tabTriggers = Array.from(container.querySelectorAll<HTMLElement>('[data-tabs-trigger]'));
    tabTriggers.forEach((tab) => tab.addEventListener('click', onClick));
    const tabs = document.querySelectorAll('.js-tabs-item.js-tabs-item--slide:not(.is-active)');
    tabs.forEach((tab) => {
        gsap.set(tab, {
            yPercent: 100,
            alpha: 0,
        });
    });
}

const _module = { init };

export default _module;
