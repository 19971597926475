import { fetchContentList, fetchContentListFilter, fetchContentListSorting } from '../dynamic-modules';
import revealAnimations from './reveal';
import { lazyload } from './lazy-load';
import likesInit from './likes-card';
import multiSelect from './multi-select';
import aboutSlider from './swiper-catalog';
import promptModal from './prompt-modal';
import { initNumberMasks, initNumberMasksWithoutDots } from './masks';
import delegate from 'delegate';
import serialize from 'form-serialize';

function init(container: HTMLElement | Document = document) {
    const catalogEl = container.querySelector<HTMLElement>('.js-catalog');
    const filterContainer = container.querySelector<HTMLElement>('.js-catalog-filter');
    const filterCountContainer = document?.querySelectorAll<HTMLElement>('.js-catalog-filters-count');
    const elementCountContainer = filterContainer?.querySelector<HTMLElement>('.js-catalog-elements-count');
    const formContainer = filterContainer?.querySelector<HTMLElement>('.js-catalog-form-inner');
    const formSort = document.querySelector('.js-catalog-filter-sort');
    const sortInputs = document.querySelectorAll<HTMLInputElement>('.js-sort-input');

    if (catalogEl) {
        Promise.all([fetchContentList(), fetchContentListFilter(), fetchContentListSorting()]).then(
            ([ContentList, ContentListFilter]) => {
                let isLoadMore = false;

                delegate(document, '.js-pagination-more', 'click', (e: Event) => {
                    e.preventDefault();
                    isLoadMore = true;
                });

                const catalogFilter = new ContentList(catalogEl, {
                    modules: [ContentListFilter],
                    pushState: false,
                    filter: {
                        el: filterContainer,
                        filterOnInputChange: true,
                        inputDebounceTime: 1000,
                    },
                    onFetchStart() {
                        formContainer?.classList.add('is-load');
                    },
                    onFetchSuccess: (instance, response) => {
                        const paginationContainer = instance.contentEl?.querySelector(
                            '.js-catalog-container-pagination',
                        );

                        if (instance.contentEl && response.data?.contentHtml) {
                            if (isLoadMore) {
                                paginationContainer?.remove();
                                instance.contentEl.innerHTML += response.data.contentHtml;

                                setTimeout(() => {
                                    isLoadMore = false;
                                }, 200);
                            } else {
                                instance.contentEl.innerHTML = response.data.contentHtml;
                                aboutSlider.init(instance.contentEl);

                                if (response.data.filters && formContainer) {
                                    setTimeout(() => {
                                        formContainer.innerHTML = response.data.filters;
                                        initNumberMasks(formContainer);
                                        initNumberMasksWithoutDots(formContainer);
                                        multiSelect.init(formContainer);
                                        revealAnimations.init(formContainer);
                                        promptModal.init();
                                    }, 300);
                                }

                                if (formContainer?.classList.contains('is-popup-fixed')) {
                                    instance.contentEl.scrollIntoView({ top: 0, behavior: 'smooth' });
                                }
                            }

                            if (elementCountContainer && response.data?.elementsCount) {
                                elementCountContainer.innerHTML = response.data?.elementsCount;
                            }

                            if (
                                response.data?.filter_url &&
                                !instance.contentEl.classList.contains('js-not-push-state')
                            ) {
                                window.history.pushState(null, '', response.data?.filter_url);
                            }

                            const changeButtonContainer = filterContainer?.querySelector('.js-button-change-container');
                            if (response.data?.buttonHtml && changeButtonContainer) {
                                changeButtonContainer.innerHTML = response.data?.buttonHtml;
                            }

                            if (response.data?.number_of_filters) {
                                filterCountContainer?.forEach(
                                    (el) => (el.textContent = response.data?.number_of_filters),
                                );
                            } else {
                                filterCountContainer?.forEach((el) => (el.textContent = ''));
                            }

                            lazyload.update();
                            likesInit.init(instance.contentEl);
                            revealAnimations.init(instance.contentEl);
                        }
                    },
                    onFetchComplete: () => {
                        setTimeout(() => {
                            formContainer?.classList.remove('is-load');
                        }, 400);
                    },
                });

                sortInputs.forEach((sort, index) => {
                    sort.addEventListener('change', () => {
                        if (sort.checked) {
                            setSort(sort.value, index);
                        }
                    });

                    function setSort(sortValue: string, index: number) {
                        const form = container.querySelector<HTMLFormElement>('.js-catalog-filter form');
                        const sortSelectDublicate =
                            form?.querySelector<HTMLSelectElement>('.js-catalog-sorting-select');
                        if (form && formSort && sortSelectDublicate) {
                            sortSelectDublicate.selectedIndex = index;

                            const formData = serialize(form);
                            const sign = /\?/.test(form.action) ? '&' : '?';
                            const url = form.action + sign + formData;

                            catalogFilter.fetchContent(url);
                        }
                    }
                });
            },
        );
    }
}

const _module = { init };

export default _module;
