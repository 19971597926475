import gsap from 'gsap';
import delegate from 'delegate';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
const header = document.querySelector<HTMLElement>('.js-header');

const popupName = 'burger-popup';
const menuPopup = document.querySelector<LitPopupElement>(`app-lit-popup[data-lit-popup="${popupName}"]`);

function init() {
    delegate(document, '[data-scrollto]', 'click', (event: any) => {
        event.preventDefault();
        const target = event.delegateTarget as HTMLLinkElement;
        const element = target.dataset.scrollto ? document.querySelector(target.dataset.scrollto) : null;
        menuPopup?.close();

        if (!element && target.dataset.scrollto) {
            window.location.href = target.href;
            return
        }

        gsap.to(window, {
            duration: 2,
            scrollTo: { y: target.dataset.scrollto, offsetY: header?.offsetHeight || 0},
            ease: 'power3.inOut',
        });
    });
}

const _module = { init };

export default _module;
