let promptModals: HTMLElement[] = [];
let promptElements: HTMLElement[] = [];
function closeModals(event: any) {
    if (!event.target.parentElement.closest('.js-prompt')) {
        promptModals.forEach((el) => {
            el.classList.remove('is-show');
        });
    }
}

function promptModalClose(event: Event) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    let modal = event.target.closest('.js-prompt').querySelector('.prompt-modal');
    if (modal.classList.contains('is-show')) {
        modal.classList.remove('is-show');
    } else {
        const rect = modal.getBoundingClientRect();
        if (rect.left < 0) {
            document.documentElement.style.setProperty('--transform-left', `${-1 * rect.left + 10}px`);
        }

        modal.classList.add('is-show');
    }
}

function init() {
    promptModals = Array.from(document.querySelectorAll<HTMLElement>('.prompt-modal'));
    promptElements = Array.from(document.querySelectorAll<HTMLElement>('.js-prompt'));

    promptElements.forEach((btn) => {
        btn.addEventListener('click', promptModalClose);
    });

    window.addEventListener('click', closeModals);
}

const _module = { init };

export default _module;
