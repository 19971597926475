let contactsMapBtns: HTMLElement[] = [];
let contactsAppMap: HTMLElement | null;

function contactsMapInit(e) {
    if (e.target.closest('.js-contacts-map-open')?.hasAttribute('data-coordinate')) {
        const coordinateRow = e.target.closest('.js-contacts-map-open').dataset.coordinate;
        const coordinateSplit = coordinateRow.split(',');
        const coordinate = [];

        coordinate.push(Number(coordinateSplit[0]), Number(coordinateSplit[1]));

        contactsAppMap?.setAttribute('center-lat', coordinateSplit[0]);
        contactsAppMap?.setAttribute('center-lng', coordinateSplit[1]);
    }

    contactsMapBtns.forEach((el) => {
        el.removeEventListener('click', contactsMapInit);
    });
}

function init(container = document) {
    contactsMapBtns = Array.from(document.querySelectorAll<HTMLElement>('.js-contacts-map-open'));
    contactsAppMap = container.querySelector('.contacts-app-map');

    contactsMapBtns.forEach((el) => {
        el.addEventListener('click', contactsMapInit);
    });
}

const _module = { init };

export default _module;
