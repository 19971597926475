import throttle from 'lodash.throttle';

let logoHeader: HTMLElement | null;
let scrollTop = Math.max(0, window.pageYOffset);
const threshold = 10;

const setLogoHandler = throttle((e) => {
    if (!logoHeader) return;
    const newScrollTop = Math.max(0, window.pageYOffset);
    if (Math.abs(newScrollTop - scrollTop) < threshold) {
        scrollTop = newScrollTop;
        return;
    }

    const scrollAfterHeader = (newScrollTop > 72);
    const logoState = (newScrollTop > scrollTop && scrollAfterHeader);

    scrollTop = newScrollTop;

    if (!logoState) {
        if (logoHeader.classList.contains('is-hidden')) {
            logoHeader.classList.remove('is-hidden');
        }
    } else if (!logoHeader.classList.contains('is-hidden')) {
        logoHeader.classList.add('is-hidden');
    }
}, 150)

function init() {
    logoHeader = document.querySelector('.js-header-logo');
    window.addEventListener('scroll', setLogoHandler);
}

const _module = { init };

export default _module;
