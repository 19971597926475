const selector = '.js-reveal, .wysiwyg > *';

const observer = new IntersectionObserver((entries, obs) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            obs.unobserve(entry.target);

            if (entry.target.closest('.js-video-decor')) {
                entry.target.classList.add('is-revealed');
                const videoRevealEl: HTMLVideoElement | null = entry.target.closest('.js-video-decor');
                if (videoRevealEl?.hasAttribute('data-delay')) {
                    const delay = Number(videoRevealEl.getAttribute('data-delay'));
                    setTimeout(() => {
                        videoRevealEl?.play();
                    }, delay * 2000);
                } else {
                    videoRevealEl?.play();
                }
            } else {
                entry.target.classList.add('is-revealed');
            }
        }
    });
});

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.observe(el);
    });
}

function veil(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.unobserve(el);
        el.classList.remove('is-revealed');
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.unobserve(el);
    });
}

function reinit(container: Element | Document = document) {
    destroy(container);

    setTimeout(() => {
        const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
        elements.forEach((el) => {
            el.classList.remove('is-revealed');
        });
    }, 1);

    setTimeout(() => {
        init(container);
    }, 2);
}

const _module = { init, veil, destroy, reinit };

export default _module;
