function init(container: HTMLElement | Document = document) {
    const tabs = container.querySelectorAll<HTMLElement>('.js-process-tab');
    const termSelects = container.querySelectorAll('.js-process-term-select')

    tabs.forEach(tab => {
        tab.addEventListener('click', () => {
            if (tab.classList.contains('is-translation')) {
                termSelects.forEach(select => select.classList.add('is-disabled'));
            } else {
                termSelects.forEach(select => select.classList.remove('is-disabled'));
            }
        })
    })
}

const _module = { init };

export default _module;
