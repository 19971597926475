export const preloaderAnimationDuration = 0.7; // sec

function createPreloader() {
    let loaded = false;

    const state = {
        completed: false,
    };

    function leave(): Promise<void> {
        return new Promise((resolve) => {
            document.dispatchEvent(new Event('preloader-leave'));
            if (!loaded) {
                loaded = true;
            }
            resolve();
        });
    }

    function loadAsset(): Promise<void> {
        return new Promise((resolve) => {
            resolve();
        });
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const images = Array.from(element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'));
        const videos = Array.from(element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"])'));
        const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];

        if (images.length > 0) {
            await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
        }
    }

    async function loadAssets() {
        await loadAssetsFromElement(document.body);
    }

    return { leave, loadAssets, state } as const;
}

export const preloader = createPreloader();
