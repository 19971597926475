import { fetchSwiper } from '../dynamic-modules';
import { executeOnIntersection } from '../utils/execute-on-intersection';
const SELECTOR = '.js-similar-slider';

function createNavigation(parent: HTMLElement) {
    parent.innerHTML = `
        <div class="slider-ctrls">
            <button class="slider-btn slider-btn--prev js-slide-prev is-disable" aria-label="предыдущий слайд">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.65685 12.3136L11.3137 6.65674M11.3137 6.65674L5.65685 0.999884M11.3137 6.65674H0" stroke="black"/>
                </svg>
            </button>
            <button class="slider-btn slider-btn--next js-slide-next" aria-label="следующий слайд">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.65685 12.3136L11.3137 6.65674M11.3137 6.65674L5.65685 0.999884M11.3137 6.65674H0" stroke="black"/>
                </svg>
            </button>
        </div>
    `;
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>(SELECTOR)).forEach(async (el) => {
        const slides = el.querySelectorAll('.swiper-slide');

        if (slides.length > 1) {
            const { Swiper, Navigation } = await fetchSwiper();
            const navigation = el.querySelector<HTMLElement>('.js-slider-navigation')
                ? el.querySelector<HTMLElement>('.js-slider-navigation')
                : document.querySelector<HTMLElement>('.js-calcualtor-slider-navigation');
            if (navigation) createNavigation(navigation);
            const prevBtn = navigation?.querySelector<HTMLElement>('.js-slide-prev');
            const nextBtn = navigation?.querySelector<HTMLElement>('.js-slide-next');

            executeOnIntersection(
                el,
                () => {
                    const slider = new Swiper(el, {
                        modules: [Navigation],
                        slidesPerView: 1,
                        spaceBetween: 16,
                        speed: 1000,
                        navigation: {
                            nextEl: nextBtn,
                            prevEl: prevBtn,
                            disabledClass: 'is-disabled',
                        },
                        breakpoints: {
                            577: {
                                slidesPerView: 2,
                            },
                            768: {
                                spaceBetween: 8,
                                slidesPerView: 2,
                            },
                            957: {
                                slidesPerView: 3,
                            },
                            1201: {
                                slidesPerView: 4,
                            },
                            1441: {
                                spaceBetween: 16,
                                slidesPerView: 4,
                            },
                        },
                    });
                },
                { rootMargin: '200px 0px 200px 0px' },
            );
        }
    });
}

const _module = { init };

export default _module;
