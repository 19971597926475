function onClick(this: HTMLElement) {
    const parent = this.closest('.js-accordion');
    const content = parent?.querySelector<HTMLElement>('.js-accordion-content');
    if (content) content.classList.toggle('is-show');
}

function init() {
    const accordionTriggers = document.querySelectorAll('.js-accordion-trigger');
    accordionTriggers.forEach(trigger => trigger.addEventListener('click', onClick))
}

export default { init };
