import type { InputMask } from 'imask';
import { fetchIMask } from '../dynamic-modules';

const map = new WeakMap<Element, InputMask<any>>();

function getInstanceByElement(element: Element) {
    return map.get(element);
}

export async function initPhoneMask(input: HTMLInputElement, dialCode = '7') {
    const IMask = await fetchIMask();
    const imask = IMask(input, { mask: `+{${dialCode}} (000) 000-00-00` });
    map.set(input, imask);
}

export function destroyPhoneMask(input: HTMLInputElement) {
    const imask = getInstanceByElement(input);
    if (imask) {
        imask.destroy();
        map.delete(input);
    }
}

function initPhoneMasks(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="phone"]'));

    if (elements.length > 0) {
        elements.forEach((el) => {
            initPhoneMask(el);
        });
    }
}

export async function initNumberMask(input: HTMLInputElement) {
    const IMask = await fetchIMask();
    const imask = IMask(input, {
        mask: Number,
        thousandsSeparator: ' ',
    });
    map.set(input, imask);
}

export function destroyNumberMask(input: HTMLInputElement) {
    const imask = getInstanceByElement(input);
    if (imask) {
        imask.destroy();
        map.delete(input);
    }
}

export async function initNumberMaskWithoutDots(input: HTMLInputElement) {
    const IMask = await fetchIMask();
    const imask = IMask(input, {
        mask: Number,
        min: 0,
        thousandsSeparator: ' ',
        scale: 0
    });
    map.set(input, imask);
}

export function destroyNumberMaskWithoutDots(input: HTMLInputElement) {
    const imask = getInstanceByElement(input);
    if (imask) {
        imask.destroy();
        map.delete(input);
    }
}

export function initNumberMasks(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="number"]'));

    elements.forEach((el) => {
        initNumberMask(el);
    });
}

export function initNumberMasksWithoutDots(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="number-without-dots"]'));

    elements.forEach((el) => {
        initNumberMaskWithoutDots(el);
    });
}

function init(container: Element | Document = document) {
    initPhoneMasks(container);
    initNumberMasks(container);
    initNumberMasksWithoutDots(container);
}

const _module = { init, getInstanceByElement };

export default _module;
