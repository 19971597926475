import axios from 'axios';

let likes: NodeListOf<HTMLButtonElement>;
let favoritesHeader: NodeListOf<HTMLButtonElement>;

export async function getRequest(url: string, id: string) {
    await axios.get(url, {
        params: {id: id},
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
        .then((responce) => {
            favoritesHeader.forEach(favorite => {
                responce.data.data?.favorites_show ?
                    favorite.classList.add('is-show') :
                    favorite.classList.remove('is-show');
            })
        })
        .catch((err) => {
            if (err.name !== 'AbortError') {
                throw err;
            }
        });
}

function toLikeCard(this: HTMLButtonElement, e: Event) {
    e.preventDefault();
    const { url, id } = this.dataset;

    if (url && id) {
        this.classList.toggle('is-active');
        getRequest(url, id);
    }
}

function init(container: HTMLElement | Document = document) {
    likes = container.querySelectorAll('.js-like');
    likes.forEach(el => el.addEventListener('click', toLikeCard));
    favoritesHeader = document.querySelectorAll('.js-header-like');
}

const _module = { init };

export default _module;
