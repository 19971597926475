import Swiper, { Navigation } from 'swiper';
import 'swiper/css';

let catalogSwiper: any;

function init(container: HTMLElement | Document = document) {
    const swiperElement = container.querySelector<HTMLElement>('.js-swiper-catalog');

    if (swiperElement && window.matchMedia('(max-width: 956px)').matches) {
        catalogSwiper = new Swiper(swiperElement, {
            grabCursor: true,
            breakpoints: {
                768: {
                    speed: 500,
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                320: {
                    speed: 500,
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            modules: [Navigation],
        });
    }

}

function destroy() {
    catalogSwiper.destroy();
    catalogSwiper = null;
}

export default { init, destroy };
