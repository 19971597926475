const header = document.querySelector<HTMLElement>('.js-header');
const commonRootMargin = '-6% 0% -93.9% 0%';
const threshold = [0, 1];
const themes = ['light', 'dark'];

const callback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
        const target = entry.target as HTMLElement;

        if (header && entry.isIntersecting) {
            const { theme } = target.dataset;
            if (!theme) {
                themes.forEach((item) => {
                    header.classList.remove(`header-theme--${item}`);
                });
                return;
            }

            themes
                .filter((item) => item !== theme)
                .forEach((item) => {
                    header.classList.remove(`header-theme--${item}`);
                });
            header.classList.add(`header-theme--${theme}`);
        }
    });
};

const map = new Map<HTMLElement, IntersectionObserver>();
const observersMap = new Map<string, IntersectionObserver>();

function createThemeObserver(rootMargin: string) {
    return new IntersectionObserver(callback, { rootMargin, threshold });
}

function init(container: Element | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-header-theme-trigger')).forEach((el) => {
        const rootMargin = el.dataset.themeObserverMargin || commonRootMargin;
        const usedObserver = observersMap.get(rootMargin);
        const observer = usedObserver || createThemeObserver(rootMargin);
        observer.observe(el);

        if (!usedObserver) {
            map.set(el, observer);
            observersMap.set(rootMargin, observer);
        }
    });
}

const _module = { init };

export default _module;
