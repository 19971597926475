import { fetchContentList, fetchContentListFilter } from '../dynamic-modules';
import axios from 'axios';
import { timeout } from '../utils/timeout';
import multiSelect from './multi-select';
import { initNumberMasks, initNumberMasksWithoutDots } from './masks';
import revealAnimations from './reveal';
import serialize from 'form-serialize';

function initVisualApartmentPicker(pickerEl: HTMLElement) {
    let pickerElRect = pickerEl.getBoundingClientRect();
    const wrapperEl = pickerEl.closest<HTMLElement>('.js-visual-apartment-picker-wrapper');
    const containerEl = pickerEl.closest<HTMLElement>('.js-visual-apartment-picker-container');
    const contentEl = pickerEl.querySelector<HTMLElement>('.js-visual-apartment-picker__content');
    const links = Array.from(pickerEl.querySelectorAll<HTMLAnchorElement>('svg a'));
    const els = Array.from(pickerEl.querySelectorAll<HTMLElement>('[data-el]'));
    const labels = Array.from(pickerEl.querySelectorAll<HTMLElement>('[data-el-label]'));
    const cards = Array.from(pickerEl.querySelectorAll<HTMLElement>('[data-el-card]'));
    const backBtn = pickerEl.querySelector<HTMLAnchorElement>('a.js-visual-apartment-picker-card-back-btn');
    const floorFilter = pickerEl.querySelectorAll<HTMLInputElement>('.js-visual-apartment-picker-floor-filter');
    const formContainer = pickerEl.querySelector<HTMLElement>('.js-catalog-form-inner');
    const floorPicture = pickerEl.querySelector<HTMLImageElement>('.js-visual-apartment-floor-picture');
    const floorMaskSvg = pickerEl.querySelector<HTMLElement>('.js-visual-apartment-floor-svg');
    let prevWidth: number | null;

    // утсанавливаем размеры для свг
    if (floorMaskSvg && floorPicture) {
        const image = new Image();

        image.onload = function () {
            const width = image.width;
            const height = image.height;
            floorMaskSvg.setAttribute('width', width + 'px');
            floorMaskSvg.setAttribute('height', height + 'px');
            floorMaskSvg.setAttribute('viewBox', `0 0 ${width} ${height}`);
        };

        image.src = floorPicture.currentSrc;
    }

    const onResize = () => {
        pickerElRect = pickerEl.getBoundingClientRect();
        const vw = window.innerWidth;

        if (matchMedia('(max-width: 1199px)').matches) {
            cards.forEach((card) => {
                document.body.appendChild(card);
            });
        } else {
            cards.forEach((card) => {
                pickerEl.appendChild(card);
            });
        }

        if (vw !== prevWidth) {
            const paths: number[] = [];

            for (let i = 0; i < els.length; i++) {
                const elementsPaths = Array.from(els[i].querySelectorAll<SVGPathElement>('path'));

                if (elementsPaths.length === 0) {
                    paths.push(els[i].getBoundingClientRect().left);
                } else {
                    elementsPaths.forEach((p) => {
                        paths.push(p.getBoundingClientRect().left);
                    });
                }
            }

            const minLeft = Math.min(...paths);

            if (minLeft >= pickerElRect.width) {
                pickerEl.scrollTo({ left: minLeft - 15, behavior: 'auto' });
            }
            prevWidth = vw;
        }
    };

    setTimeout(onResize, 500);
    window.addEventListener('resize', onResize);

    const onLinkClick = (link: HTMLAnchorElement) => {
        const href = link.getAttribute('href');

        if (href) {
            if (link.hasAttribute('data-group')) {
                wrapperEl?.classList.add('is-loading');
                const card = cards.find((card) => card.dataset.elCard === link.dataset.el);
                card?.classList.remove('is-visible');
                const form = formContainer?.querySelector<HTMLFormElement>('form');
                let url = href;

                if (form) {
                    const formData = serialize(form);
                    const sign = /\?/.test(href) ? '&' : '?';
                    url = href + sign + formData;
                }

                const requestPromise = axios.get(url);

                Promise.all([requestPromise, timeout(1000)])
                    .then(([{ data }]) => {
                        if (containerEl) {
                            destroyVisualApartmentPicker(pickerEl);
                            containerEl.innerHTML = data.data.html;
                            setTimeout(() => {
                                const newPickerEl =
                                    containerEl.querySelector<HTMLElement>('.js-visual-apartment-picker');
                                if (newPickerEl) {
                                    document.body.classList.remove('no-scroll');
                                    initVisualApartmentPicker(newPickerEl);
                                    multiSelect.init(newPickerEl);
                                    initNumberMasks(newPickerEl);
                                    initNumberMasksWithoutDots(newPickerEl);
                                    revealAnimations.init(newPickerEl);
                                }
                            }, 300);
                        }
                    })
                    .finally(() => {
                        wrapperEl?.classList.remove('is-loading');
                    });
            } else {
                window.open(href, '_blank');
            }
        }
    };

    els.forEach((el) => {
        const label = labels.find((label) => label.dataset.elLabel === el.dataset.el);
        const card = cards.find((card) => card.dataset.elCard === el.dataset.el);
        const cardCloseBtn = card?.querySelector('.js-visual-apartment-picker-card-close-btn');
        const cardGoDeeperBtn = card?.querySelector('.js-visual-apartment-picker-card-next-btn');

        const calculatePosition = () => {
            const rect = el.getBoundingClientRect();

            if (label && contentEl) {
                label.style.top = `${rect.top - pickerElRect.top + 30}px`;
                label.style.left = `${Math.min(
                    rect.left - label.getBoundingClientRect().width - pickerElRect.left,
                    pickerEl.scrollWidth - 10 - label.offsetWidth,
                )}px`;
            }

            if (card) {
                card.style.top = `${rect.top - pickerElRect.top}px`;
                card.style.left = `${Math.max(rect.left - pickerElRect.left - card.offsetWidth - 20, 10)}px`;
            }
        };

        calculatePosition();
        setTimeout(calculatePosition, 500);
        window.addEventListener('resize', calculatePosition);

        const showCard = () => {
            card?.classList.add('is-visible');

            if (matchMedia('(max-width: 1199px)').matches) {
                document.body.classList.add('no-scroll');
            }
        };

        const hideCard = () => {
            card?.classList.remove('is-visible');
            document.body.classList.remove('no-scroll');
        };

        el.addEventListener('mouseenter', () => {
            if (matchMedia('(any-hover: hover), (hover: hover) and (pointer: fine)').matches) {
                showCard();
            }
        });

        el.addEventListener('mouseleave', () => {
            if (matchMedia('(any-hover: hover), (hover: hover) and (pointer: fine)').matches) {
                hideCard();
            }
        });

        el.addEventListener('click', () => {
            if (!matchMedia('(any-hover: hover), (hover: hover) and (pointer: fine)').matches) {
                showCard();
            }
        });

        cardCloseBtn?.addEventListener('click', hideCard);
        cardGoDeeperBtn?.addEventListener('click', () => {
            const link = links.find((link) => link.dataset.el === el.dataset.el);

            if (link) {
                onLinkClick(link);
            }
        });
    });

    // выравниваем все лейблы этажей по одной линии
    if (pickerEl.classList.contains('js-visual-apartment-corpus')) {
        setTimeout(() => {
            const leftValues: { [key: string]: number } = {};

            labels.forEach(function (label) {
                const left = getComputedStyle(label).getPropertyValue('left');

                if (leftValues[left]) {
                    leftValues[left]++;
                } else {
                    leftValues[left] = 1;
                }
            });

            const mostFrequentLeft = Object.keys(leftValues).reduce(function (a, b) {
                return leftValues[a] > leftValues[b] ? a : b;
            });

            labels.forEach(function (label) {
                label.style.left = mostFrequentLeft;
            });
        }, 500);
    }

    links.forEach((link) => {
        link.addEventListener('click', (event) => {
            event.preventDefault();

            if (matchMedia('(any-hover: hover), (hover: hover) and (pointer: fine)').matches) {
                onLinkClick(link);
            }
        });
    });
    const onFloorChange = (input: HTMLInputElement) => {
        const url = input.dataset.url;

        if (url) {
            wrapperEl?.classList.add('is-loading');
            const card = cards.find((card) => card.dataset.elCard === input.dataset.el);
            card?.classList.remove('is-visible');
            const requestPromise = axios.get(url);

            Promise.all([requestPromise, timeout(1000)])
                .then(([{ data }]) => {
                    if (containerEl) {
                        destroyVisualApartmentPicker(pickerEl);
                        containerEl.innerHTML = data.data.html;
                        setTimeout(() => {
                            const newPickerEl = containerEl.querySelector<HTMLElement>('.js-visual-apartment-picker');
                            if (newPickerEl) {
                                document.body.classList.remove('no-scroll');
                                initVisualApartmentPicker(newPickerEl);
                                multiSelect.init(newPickerEl);
                                initNumberMasks(newPickerEl);
                                initNumberMasksWithoutDots(newPickerEl);
                                revealAnimations.init(newPickerEl);
                            }
                        }, 300);
                    }
                })
                .finally(() => {
                    wrapperEl?.classList.remove('is-loading');
                });
        }
    };

    floorFilter.forEach((filter) => {
        filter.addEventListener('change', () => {
            onFloorChange(filter);
        });
    });

    backBtn?.addEventListener('click', function onBackBtnClick(event) {
        event.preventDefault();

        const href = backBtn.getAttribute('href');

        const form = formContainer?.querySelector<HTMLFormElement>('form');
        let url = href;

        if (form && href) {
            const formData = serialize(form);
            const sign = /\?/.test(href) ? '&' : '?';
            url = href + sign + formData;
        }

        if (url) {
            wrapperEl?.classList.add('is-loading');
            const requestPromise = axios.get(url);

            Promise.all([requestPromise, timeout(1000)])
                .then(([{ data }]) => {
                    if (containerEl) {
                        backBtn.removeEventListener('click', onBackBtnClick);
                        destroyVisualApartmentPicker(pickerEl);
                        containerEl.innerHTML = data.data.html;
                        const newPickerEl = containerEl.querySelector<HTMLElement>('.js-visual-apartment-picker');
                        if (newPickerEl) {
                            initVisualApartmentPicker(newPickerEl);
                            multiSelect.init(newPickerEl);
                            initNumberMasks(newPickerEl);
                            initNumberMasksWithoutDots(newPickerEl);
                            revealAnimations.init(newPickerEl);
                        }
                    }
                })
                .finally(() => {
                    wrapperEl?.classList.remove('is-loading');
                });
        }
    });

    if (wrapperEl) {
        Promise.all([fetchContentList(), fetchContentListFilter()]).then(([ContentList, ContentListFilter]) => {
            new ContentList(wrapperEl, {
                modules: [ContentListFilter],
                pushState: false,
                filter: {
                    el: formContainer,
                    filterOnInputChange: true,
                    inputDebounceTime: 1000,
                },
                onFetchStart() {
                    wrapperEl?.classList.add('is-loading');
                },
                onFetchSuccess: (instance, response) => {
                    if (containerEl) {
                        destroyVisualApartmentPicker(pickerEl);
                        containerEl.innerHTML = response.data.html;
                        setTimeout(() => {
                            const newPickerEl = containerEl.querySelector<HTMLElement>('.js-visual-apartment-picker');
                            if (newPickerEl) {
                                document.body.classList.remove('no-scroll');
                                initVisualApartmentPicker(newPickerEl);
                                multiSelect.init(newPickerEl);
                                initNumberMasks(newPickerEl);
                                initNumberMasksWithoutDots(newPickerEl);
                                revealAnimations.init(newPickerEl);
                                wrapperEl?.classList.remove('is-loading');
                            }
                        }, 300);
                    }
                },
                onFetchComplete: () => {
                    setTimeout(() => {
                        wrapperEl?.classList.remove('is-load');
                    }, 400);
                },
            });
        });
    }
}

function destroyVisualApartmentPicker(pickerEl: HTMLElement) {
    //
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-visual-apartment-picker-container')).forEach((pickerEl) => {
        const el = pickerEl.querySelector<HTMLElement>('.js-visual-apartment-picker');

        if (el) {
            initVisualApartmentPicker(el);
        }
    });
}

const _module = { init };

export default _module;
