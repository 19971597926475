function init(container: HTMLElement | Document = document) {
    const arrVideoTrigger = container.querySelectorAll('.js-video-wrapper');

    arrVideoTrigger.forEach(videoTrigger => {
        let btnPlayVideo = videoTrigger.querySelector<HTMLElement>('.js-video-play')
        let video = videoTrigger.querySelector<HTMLVideoElement>('.video')
        let videoPoster = videoTrigger.querySelector<HTMLElement>('.js-video-poster')
        function onTrgerClick(this: HTMLElement, event: Event) {
            video?.setAttribute('controls','');
            video?.play();
            btnPlayVideo?.classList.add('is-hide');
            videoPoster?.classList.remove('is-show');
        }

        if (btnPlayVideo) {
            btnPlayVideo.addEventListener('click', onTrgerClick);
        }
    });
}

const _module = { init };

export default _module;
