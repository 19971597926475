import { fetchSwiper } from '../dynamic-modules';
import { executeOnIntersection } from '../utils/execute-on-intersection';
const SELECTOR = '.js-promotions-slider';

const map = new Map<Element, any>();

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>(SELECTOR)).forEach(async (el) => {
        const slides = el.querySelectorAll('.swiper-slide');

        if (slides.length > 1) {
            const { Swiper } = await fetchSwiper();

            executeOnIntersection(
                el,
                () => {
                    const slider = new Swiper(el, {
                        slidesPerView: 'auto',
                        spaceBetween: 16,
                        speed: 1000,
                        breakpoints: {
                            768: {
                                spaceBetween: 8,
                            },
                            1441: {
                                spaceBetween: 16,
                            },
                        },
                    });

                    map.set(el, slider);
                },
                { rootMargin: '200px 0px 200px 0px' },
            );
        }
    });
}

const _module = { init };

export default _module;
