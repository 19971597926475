import { fetchContentList, fetchContentListFilter } from '../dynamic-modules';
import serialize from 'form-serialize';
import revealAnimations from './reveal';
import { lazyload } from './lazy-load';
import tabs from './tabs';
import multiSelect from './multi-select';
import housingSlider from './housing-slider';

function init(container: HTMLElement | Document = document) {
    const catalogEl = container.querySelector<HTMLElement>('.js-process');
    const filterContainer = container.querySelector<HTMLElement>('.js-process-filter');

    if (catalogEl) {
        Promise.all([fetchContentList(), fetchContentListFilter()]).then(([ContentList, ContentListFilter]) => {
            const processFilter = new ContentList(catalogEl, {
                modules: [ContentListFilter],
                pushState: false,
                filter: {
                    el: filterContainer,
                    filterOnInputChange: true,
                },
                onFetchSuccess: (instance, response) => {
                    if (instance.contentEl && response.data?.contentHtml) {
                        instance.contentEl.innerHTML = response.data.contentHtml;
                        lazyload.update();
                        multiSelect.init(instance.contentEl);
                        tabs.init(instance.contentEl);
                        housingSlider.init(instance.contentEl);
                        revealAnimations.init(instance.contentEl);
                    }

                    if (response.data.filters && filterContainer) {
                        filterContainer.classList.add('is-load');

                        setTimeout(() => {
                            filterContainer.innerHTML = response.data.filters;
                            multiSelect.init(filterContainer);

                            setTimeout(() => {
                                filterContainer.classList.remove('is-load');
                            }, 100);
                        }, 300);
                    }

                    if (response.data?.filter_url) {
                        window.history.pushState(null, '', response.data?.filter_url);
                    }
                },
            });
        });
    }
}

const _module = { init };

export default _module;
