import gsap from 'gsap';
import { Swiper, Navigation } from 'swiper';
import { viewport } from '../viewport';

function init(container = document) {
    const wrapper = container.querySelector<HTMLElement>('.js-slogan');

    let decorPlay = false;

    if (!wrapper) {
        return;
    }

    if (window.matchMedia('(max-width: 1099px)').matches) {
        const slides = Array.from(wrapper.querySelectorAll('.swiper-slide'));

        if (slides.length < 2) return;

        function playSlide(slide: Element, action: 'enter' | 'leave') {
            if (action === 'leave') {
                slides
                    .find((slide) => slide.classList.contains('swiper-slide-was-active'))
                    ?.classList.remove('swiper-slide-was-active');
                slide.classList.add('swiper-slide-was-active');

                slides.forEach((slide) => {
                    slide.classList.remove('rtl-animation');
                    slide.classList.remove('ltr-animation');
                });
            }
        }

        new Swiper(wrapper, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 0,
            virtualTranslate: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                disabledClass: 'is-disabled',
            },
            on: {
                slideChange: (swiper) => {
                    playSlide(slides[swiper.previousIndex], 'leave');

                    if (swiper.previousIndex > swiper.activeIndex) {
                        slides[swiper.activeIndex].classList.add('ltr-animation');
                    } else {
                        slides[swiper.activeIndex].classList.add('rtl-animation');
                    }

                    const sloganDecor = slides[swiper.activeIndex]?.querySelector<HTMLVideoElement>('.js-video-decor');

                    if (sloganDecor && !decorPlay) {
                        decorPlay = true;
                        sloganDecor?.play();
                    }

                    if (swiper.activeIndex == slides.length - 1) {
                        wrapper.classList.add('is-light');
                    } else {
                        wrapper.classList.remove('is-light');
                    }
                },
            },
        });
    } else {
        const sloganDecor = wrapper?.querySelector<HTMLVideoElement>('.js-video-decor');

        const coverTl = gsap.timeline({
            scrollTrigger: {
                trigger: wrapper,
                start: 'top +=12px',
                end: `+=${viewport.height * 3}`,
                scrub: 0.5,
                anticipatePin: 1,
                onUpdate: (self) => {
                    if (self.progress > 0.8) {
                        if (!decorPlay) {
                            decorPlay = true;
                            sloganDecor?.play();
                        }
                    }
                },
            },
        });

        coverTl.to('.js-slogan-first', { x: '100%' }, 0);
        coverTl.to('.js-slogan-wrapper-first', { x: '-100%' }, 0);

        coverTl.to('.js-slogan-second', { x: '100%' }, 0.5);
        coverTl.to('.js-slogan-wrapper-second', { x: '-100%' }, 0.5);

        coverTl.to('.js-slogan-third', { x: '100%' }, 1);
        coverTl.to('.js-slogan-wrapper-third', { x: '-100%' }, 1);
    }
}

const _module = { init };

export default _module;
