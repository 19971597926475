/* eslint-disable max-len */
import { fetchSwiper } from '../dynamic-modules';
const SELECTOR = '.js-credits-slider';
import gsap from 'gsap';

const map = new Map<Element, any>();
let progressbars: NodeListOf<HTMLElement>;

function createNavigation(parent: HTMLElement) {
    parent.innerHTML = `
        <div class="slider-ctrls">
            <button class="slider-btn slider-btn--prev js-slide-prev is-disable" aria-label="предыдущий слайд">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.65685 12.3136L11.3137 6.65674M11.3137 6.65674L5.65685 0.999884M11.3137 6.65674H0" stroke="black"/>
                </svg>
            </button>
            <button class="slider-btn slider-btn--next js-slide-next" aria-label="следующий слайд">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.65685 12.3136L11.3137 6.65674M11.3137 6.65674L5.65685 0.999884M11.3137 6.65674H0" stroke="black"/>
                </svg>
            </button>
        </div>
    `;
}

const createPagination = (slidesLength: number, pagination: HTMLElement) => {
    for (let i = 0; i < slidesLength; i++) {
        const paginationItem = document.createElement('span');
        paginationItem.classList.add('image-slider__pagination-item', 'js-image-slider-progressbar');
        pagination.appendChild(paginationItem);
    }
    progressbars = pagination.querySelectorAll('.js-image-slider-progressbar');
};

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>(SELECTOR)).forEach(async (el) => {
        const sliderContainer: HTMLElement | null = el.closest('.js-credits-slider-container');
        const slides = el.querySelectorAll('.swiper-slide');

        if (slides.length > 1) {
            const { Swiper, Pagination, Navigation, EffectCreative, Autoplay } = await fetchSwiper();

            const textBlocks = sliderContainer?.querySelectorAll<HTMLElement>('.js-credits-slider-text');
            const navigation = sliderContainer?.querySelector<HTMLElement>('.js-slider-navigation');
            const pagination = sliderContainer?.querySelector<HTMLElement>('.js-slider-points-pagination');
            const countPagination = sliderContainer?.querySelector<HTMLElement>('.js-slider-pagination');
            if (navigation) createNavigation(navigation);
            const prevBtn = navigation?.querySelector<HTMLElement>('.js-slide-prev');
            const nextBtn = navigation?.querySelector<HTMLElement>('.js-slide-next');
            const autoplayDuration = 7000;

            const slider = new Swiper(el, {
                modules: [Pagination, Navigation, EffectCreative, Autoplay],
                effect: 'creative',
                creativeEffect: {
                    prev: {
                        translate: [0, 0, -1],
                    },
                    next: {
                        translate: ['100%', 0, 0],
                    },
                    limitProgress: 10,
                },
                speed: 500,
                pagination: {
                    el: countPagination,
                    type: 'fraction',
                    formatFractionCurrent: function (number) {
                        return ('0' + number).slice(-2);
                    },
                    formatFractionTotal: function (number) {
                        return ('0' + number).slice(-2);
                    },
                    renderFraction: function (currentClass, totalClass) {
                        return (
                            '<span class="' +
                            currentClass +
                            '"></span>' +
                            ' / ' +
                            '<span class="' +
                            totalClass +
                            '"></span>'
                        );
                    },
                },
                navigation: {
                    nextEl: nextBtn,
                    prevEl: prevBtn,
                    disabledClass: 'is-disabled',
                },
                autoplay: {
                    delay: autoplayDuration,
                    disableOnInteraction: false,
                    waitForTransition: false,
                },
                on: {
                    beforeInit: () => {
                        if (pagination) {
                            createPagination(slides.length, pagination);
                        }
                    },
                    init: () => {
                        if (sliderContainer)
                            sliderContainer.style.setProperty('--autoplay-duration', `${autoplayDuration}ms`);
                        progressbars[0].classList.add('is-active');

                        const tl = gsap.timeline();
                        if (textBlocks) {
                            tl.set(textBlocks, {
                                alpha: 0,
                            }).set(textBlocks[0], {
                                alpha: 1,
                            });
                            textBlocks?.forEach((block) => (block.style.height = '0'));
                            textBlocks[0].style.height = '';
                        }

                        // проверяем, ушел ли пользователь с текущей вкладки, чтобы приостановить автоплей
                        handleVisibilityChange();
                        document.addEventListener('visibilitychange', handleVisibilityChange);
                    },
                    slideChange: (swiper) => {
                        progressbars.forEach((bar, index) => {
                            if (index === swiper.realIndex) {
                                bar.classList.add('is-active');
                            } else {
                                bar.classList.remove('is-active');
                            }
                        });

                        const tl = gsap.timeline({
                            defaults: {
                                duration: 0.15,
                            },
                        });
                        if (textBlocks) {
                            tl.to(textBlocks, {
                                alpha: 0,
                                onComplete: () => {
                                    textBlocks?.forEach((block) => (block.style.height = '0'));
                                    textBlocks[swiper.realIndex].style.height = '';
                                },
                            }).to(textBlocks[swiper.realIndex], {
                                alpha: 1,
                            });
                        }
                    },
                },
            });

            function handleVisibilityChange() {
                if (document.visibilityState === 'visible') {
                    // Если вкладка активна, возобновляем автоплей
                    if (slider && slider.autoplay) {
                        slider.autoplay.start();
                    }
                } else {
                    // Если вкладка неактивна, приостанавливаем автоплей
                    if (slider && slider.autoplay) {
                        slider.autoplay.stop();
                    }
                }
            }

            map.set(el, slider);
        }
    });
}

const _module = { init };

export default _module;
