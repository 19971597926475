import SlimSelect from 'slim-select';

let tabTriggers: HTMLElement[] = [];

let selectedTabValue: string | undefined | null | string[];
selectedTabValue = document.querySelector('.layout-tab-btn.is-active')?.getAttribute('data-tabs-layout-trigger');
let selectLayout: HTMLElement | null;
let selectLayoutEuro: HTMLElement | null;

let newselectEuro: SlimSelect;
let newselect: SlimSelect;

function selectsInit() {
    if (selectLayout) {
        newselect = new SlimSelect({
            select: selectLayout,
            showSearch: false,
            placeholder: selectLayout?.dataset.namePlaceholder,
            showContent: 'down',
            onChange: function (info) {
                selectedTabValue = newselect.selected();

                activeTabsContent();

                newselectEuro.disable();
                newselectEuro.enable();
            },
        });
    }

    if (selectLayoutEuro) {
        newselectEuro = new SlimSelect({
            select: selectLayoutEuro,
            showSearch: false,
            placeholder: selectLayoutEuro?.dataset.namePlaceholder,
            showContent: 'down',
            onChange: function (info) {
                selectedTabValue = newselectEuro.selected();

                activeTabsContent();

                newselectEuro.disable();
                newselectEuro.enable();
            },
        });
    }
}

function activeTabsContent() {
    let tabsContent;
    let container = document.querySelector('.layout-section');
    let tabs = container?.querySelectorAll('[data-tabs-content]');

    tabs?.forEach((el) => {
        el.classList.remove('is-active');
    });

    const nameTabsSplit = selectedTabValue?.split('-euro');

    if (nameTabsSplit?.length == 2) {
        tabsContent = document.querySelectorAll(`[data-tabs-content="${nameTabsSplit[0]}-euro"]`);
    } else if (nameTabsSplit?.length == 1) {
        tabsContent = document.querySelectorAll(`[data-tabs-content="${nameTabsSplit[0]}"]`);
    }

    tabsContent?.forEach((el) => {
        el.classList.add('is-active');
    });
}

function onClick(this: HTMLElement) {
    if (this.classList.contains('is-active')) return;
    const container = this.closest('.js-tabs-container-layout');
    const tabTriggers = container?.querySelectorAll<HTMLElement>('[data-tabs-layout-trigger]');
    const tabs = container?.querySelectorAll('[data-tabs-content]');
    const value = this.dataset.tabsLayoutTrigger;
    const activeTabs = container?.querySelectorAll(`[data-tabs-content="${value}"]`);

    selectedTabValue = this.dataset.tabsLayoutTrigger;

    tabs?.forEach((tab) => {
        tab.classList.remove('is-active');
    });

    activeTabs?.forEach((el) => {
        el.classList.add('is-active');
    });

    tabTriggers?.forEach((tab) => tab.classList.remove('is-active'));
    this.classList.add('is-active');
}

function layoutChange() {
    const containerlayout = document.querySelector<HTMLElement>('.js-tabs-container-layout');
    // eslint-disable-next-line max-len
    const tabTriggers = containerlayout ? Array.from(containerlayout.querySelectorAll<HTMLElement>('[data-tabs-layout-trigger]')) : null;
    const tabs = containerlayout?.querySelectorAll('[data-tabs-content]');

    function tabsRemoveActive() {
        tabs?.forEach((el) => {
            el.classList.remove('is-active');
        });

        tabTriggers?.forEach((el) => {
            el.classList.remove('is-active');
        });
    }

    if (euroChackInput?.checked) {
        tabsControl?.classList.add('layout-tab-controls--euro-active');
        tabsRemoveActive();

        const euroEl = document.querySelector(`[data-tabs-layout-trigger="${selectedTabValue}-euro"]`);

        if (euroEl) {
            euroEl.classList.add('is-active');
            newselectEuro.set(`${selectedTabValue}-euro`);
        } else {
            const even = (tab: HTMLElement) => tab.dataset.tabsLayoutTrigger && tab.dataset.tabsLayoutTrigger.match('euro');
            const tab = tabTriggers?.findIndex(even);
            if (tab !== undefined && tab >= 0 && tabTriggers) {
                tabTriggers[tab].classList.add('is-active');
                newselectEuro.set(tabTriggers[tab].dataset.tabsLayoutTrigger);
            }
        }
    } else {
        tabsControl?.classList.remove('layout-tab-controls--euro-active');
        tabsRemoveActive();

        const nameTabsSplit = selectedTabValue?.split('-euro');
        const elNotEuro = document.querySelector(`[data-tabs-layout-trigger="${nameTabsSplit[0]}"]`);

        if (elNotEuro) {
            elNotEuro.classList.add('is-active');
            newselect.set(`${nameTabsSplit[0]}`);
        } else {
            const even = (tab: HTMLElement) => tab.dataset.tabsLayoutTrigger && !tab.dataset.tabsLayoutTrigger.match('euro');
            const tab = tabTriggers?.findIndex(even);
            if (tab !== undefined && tab >= 0 && tabTriggers) {
                tabTriggers[tab].classList.add('is-active');
                newselect.set(tabTriggers[tab].dataset.tabsLayoutTrigger);
            }
        }
    }
}

const euroChackInput = document.querySelector<HTMLInputElement>('.euro-check');
const tabsControl = document.querySelector<HTMLInputElement>('.layout-controls');

euroChackInput?.addEventListener('change', layoutChange);

function init() {
    selectLayout = document.querySelector<HTMLElement>('.js-dropdown-layout');
    selectLayoutEuro = document.querySelector<HTMLElement>('.js-dropdown-layout-euro');

    selectsInit();
    const containerlayout = document.querySelector<HTMLElement>('.js-tabs-container-layout');

    if (containerlayout) {
        tabTriggers = Array.from(containerlayout.querySelectorAll<HTMLElement>('[data-tabs-layout-trigger]'));
    }

    tabTriggers.forEach((tab) => tab.addEventListener('click', onClick));
}

const _module = { init };

export default _module;
