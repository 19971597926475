import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { executeOnIntersection } from '../utils/execute-on-intersection';

const selector : string = '.js-reveal-mask';

function splittedText(el: HTMLElement, speedKoef = 1) {
    el.classList.add('splittable');

    const splittedParent = new SplitText(el, {
        type: 'lines',
        linesClass: 'title-line-parent',
    });

    const splitted = new SplitText(el.getElementsByClassName('title-line-parent'), {
        type: 'lines',
    });

    const tl = gsap.timeline({
        defaults: { duration: 1 * speedKoef, ease: 'cubic-bezier(.25,.46,.45,.94)' },
        paused: true,
        onStart: () => {
            el.classList.add('splittable--initialized');
        },
        onComplete: () => {
            if (el.querySelector('.js-video-decor')) {
                const videoRevealEl : HTMLVideoElement | null = el.querySelector('.js-video-decor');
                if (videoRevealEl?.hasAttribute('data-delay')) {
                    const delay = Number(videoRevealEl.getAttribute('data-delay'));
                    setTimeout(() => {
                        videoRevealEl?.play();
                    }, delay * 2000);
                } else {
                    videoRevealEl?.play();
                }
            }
            el.classList.add('splittable--complete');
        }
    });

    tl.fromTo(
        splitted.lines,
        { yPercent: 105 },
        {
            yPercent: 0,
            duration: 0.8 * speedKoef,
            onComplete: function () {
                splittedParent.revert();
                splitted.revert();
            },
        },
    );

    return tl;
}

function reinit(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));

    setTimeout(() => {
        elements.forEach((el) => {
            el.classList.remove('splittable--initialized');
            el.classList.remove('splittable');
        });
    }, 1);

    setTimeout(() => {
        init();
    }, 2);
}

function init() {
    Array.from(document.querySelectorAll<HTMLElement>(selector)).forEach((el) => {
        const tl = splittedText(el);
        executeOnIntersection(el, () => tl.play(), { threshold: 0.8 });
    });
}

const _module = { init, reinit };

export default _module;
