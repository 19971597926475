

let menu : HTMLElement | null;
let menuBtn : HTMLElement | null;
let header : HTMLElement | null;

function menuToggle() {
    header?.classList.toggle('header--menu-opened')
    menu?.classList.toggle('is-open')
}
function init() {
    header = document.querySelector<HTMLElement>('.js-header');
    menu = document.querySelector('.header__nav');
    menuBtn = document.querySelector('.js-menu-toggle-btn');

    menuBtn?.addEventListener('click', menuToggle);
}

const _module = { init };

export default _module;
