import './CookiesAgreement/CookiesAgreement';
import './LitPopupElement/LitPopupElement';
import { YandexMap } from './YandexMap/YandexMap';
import { Tabs } from './Tabs/Tabs';
// import './Collapse/Collapse';
// import './WebShare/WebShare';

export function initCustomElements() {
    customElements.define('app-tabs', Tabs);
    customElements.define('app-yandex-map', YandexMap);
    //  customElements.define('app-web-share', WebShare);
}
