import Swiper, {} from 'swiper';

let sliders: HTMLElement[] = [];
const map = new WeakMap();

function sliderInit() {
    if (sliders) {
        sliders.forEach((sliderEl) => {
            const swiper = new Swiper(sliderEl, {
                loop: false,
                slidesPerView:'auto',
                grabCursor: true,
                spaceBetween: 2,
                breakpoints: {
                    767: {
                        spaceBetween: 4,
                    },
                },
            })
            map.set(sliders, swiper);
        });
    }
}

function init(container: HTMLElement | Document = document) {
    sliders = Array.from(container.querySelectorAll<HTMLElement>('.js-filters-drag'));
    sliderInit();
}

const _module = { init };

export default _module;
