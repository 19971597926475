import SlimSelect from 'slim-select';
const map = new WeakMap<HTMLElement, any>();

function updateSelect(select : SlimSelect) {
    select.disable();
    select.enable();
}

function init(container: HTMLElement | Document = document) {
    const selectList = container.querySelectorAll<HTMLElement>('.js-dropdown');

    selectList.forEach(select => {
        if (!select.classList.contains('select--initialization')) {
            const newselect = new SlimSelect({
                select: select,
                showSearch: false,
                placeholder: select.dataset.namePlaceholder,
                showContent: 'down',
                onChange() {
                    newselect.disable();
                    newselect.enable();
                }
            });
            map.set(select, newselect);

            select.classList.add('select--initialization');
        }
    })
}

const _module = { init };

export default _module;
