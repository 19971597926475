import Swiper, {
    Navigation, Pagination
} from 'swiper';

let purchaseSliderElement : HTMLElement | null;
let purchaseSlides : HTMLElement[] = []
let  purchaseSlider : Swiper | null

const sliderOptions = {
    modules: [Pagination, Navigation],
    spaceBetween: 8,
    speed: 800,
    slidesPerView: 1,
     allowTouchMove: true,

     pagination: {
        el: '.purchase-section__pagination',
        type: 'bullets',
      },

      
    breakpoints: {
        768: {
            slidesPerView: 1,
            spaceBetween: 8,
        },
    }
}


function sliderInit() {
    if (purchaseSliderElement && purchaseSlides.length > 1) {
         purchaseSlider = new Swiper(purchaseSliderElement, sliderOptions)
    }
}



function onResize() {
    if (window.matchMedia('(max-width: 1280px)').matches) {

        if (!purchaseSlider) {
            sliderInit()
        }
    } else {
        if (purchaseSlider) {
            purchaseSlider.destroy(true,true);
            purchaseSlider = null;
        }
    }
}

function init(container: HTMLElement | Document = document) {
    purchaseSliderElement = container.querySelector<HTMLElement>('.js-purchase-slider');
    purchaseSlides = Array.from(container.querySelectorAll<HTMLElement>('.purchase-section__item'));
    onResize();
    window.addEventListener('resize', onResize);
}

function destroy(container: HTMLElement | Document = document) {

    window.removeEventListener('resize', onResize);

    if (purchaseSlider) {
        purchaseSlider.destroy();
    }
}

const _module = { init, destroy };

export default _module;
