export function executeOnIntersection(el: Element, fn: () => void, observerOptions: IntersectionObserverInit = {}) {
    const observer = new IntersectionObserver((entries, obs) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                obs.disconnect();
                fn();
            }
        });
    }, observerOptions);

    observer.observe(el);
}

export function webglObserver(
    el: Element,
    webglSketch: any,
    fn: () => void, observerOptions: IntersectionObserverInit = {}
) {
    const observer = new IntersectionObserver((entries, obs) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (!entry.target.classList.contains('is-initialized-slider')) {
                    fn();
                } else {
                    if (webglSketch && webglSketch.enable) webglSketch.enable();
                }
            } else {
                if (webglSketch && webglSketch.disable) webglSketch.disable();
            }
        });
    }, observerOptions);

    observer.observe(el);
}
