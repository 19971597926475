import { getOffsetTop } from '../utils/dom';

let calculatorTabsContainer: HTMLElement | null;

const scrollToActiveTab = (activeTab: HTMLElement) => {
    const tabsWrapper = calculatorTabsContainer?.querySelector<HTMLElement>('.js-calculator-tabs-wrapper');
    if (!tabsWrapper) return;

    if (window.matchMedia('(min-width: 1270px)').matches) {
        // проверяем, видно ли таб в контейнере
        const positionTab =
            activeTab.getBoundingClientRect().y +
            activeTab.getBoundingClientRect().height -
            tabsWrapper.getBoundingClientRect().y -
            tabsWrapper.getBoundingClientRect().height;

        // если таб не находится в зоне видимости, то скролим контейнер
        if (positionTab > 0) {
            tabsWrapper.scrollTop = activeTab.getBoundingClientRect().y - tabsWrapper.getBoundingClientRect().y;
        }
    } else {
        // проверяем, видно ли таб в контейнере
        const positionTab =
            activeTab.getBoundingClientRect().x +
            activeTab.getBoundingClientRect().width -
            tabsWrapper.getBoundingClientRect().x -
            tabsWrapper.getBoundingClientRect().width;

        // если таб не находится в зоне видимости, то скролим контейнер
        if (positionTab > 0) {
            tabsWrapper.scrollLeft = activeTab.getBoundingClientRect().x - tabsWrapper.getBoundingClientRect().x;
        }
    }
};

const setActiveTab = () => {
    const tagName = window.location.hash.replace('#', '');
    const activeTab = calculatorTabsContainer?.querySelector<HTMLElement>(`[data-tabs-trigger="${tagName}"]`);
    if (activeTab) {
        activeTab.click();
        scrollToActiveTab(activeTab);
    }
};

function init(container: HTMLElement | Document = document) {
    calculatorTabsContainer = container.querySelector('.js-calculator-tabs');

    if (calculatorTabsContainer && window.location.hash) {
        setActiveTab();

        const header = document.querySelector<HTMLElement>('.js-header');
        const y = getOffsetTop(calculatorTabsContainer, window.scrollY, header?.offsetHeight);
        window.scrollTo({ top: y, behavior: 'auto' });
    }
}

const _module = { init };

export default _module;
