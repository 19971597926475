const TriggerSelector = '[data-map-filter]';

function onClick(this: HTMLInputElement) {
    setFilters(this);
}

// фильтруем марукры
function setFilters(el: HTMLInputElement) {
    const container = el.closest('.js-map-container');
    const tabTriggers = container?.querySelectorAll<HTMLInputElement>('[data-map-filter]');
    const tabTriggerAll = container?.querySelector<HTMLInputElement>('[data-map-filter="all"]');
    const mapMarkers = container?.querySelectorAll<HTMLElement>('.js-map-marker:not(.js-no-filter)');

    mapMarkers?.forEach(marker => {
        marker.classList.remove('is-active');
    });

    // если выбран фильтр "Все линейки", убираем чекбокс с других фильтров, и наоборот
    if (el.dataset.mapFilter == 'all') {
        tabTriggers?.forEach(checkbox => {
            if (checkbox !== el) {
                checkbox.checked = false;
            }
        })
    } else {
        if (tabTriggerAll && tabTriggerAll.checked) {
            tabTriggerAll.checked = false;
        }
    }

    // триггерим вызов функции для карты
    container?.dispatchEvent(new Event('setFilters'));
}

function init() {
    const tabTriggers = Array.from(document.querySelectorAll<HTMLInputElement>(TriggerSelector));

    tabTriggers.forEach(tab => tab.addEventListener('change', onClick));
}

const _module = { init };

export default _module;
