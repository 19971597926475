/* eslint-disable max-len */
import { fetchSwiper } from '../dynamic-modules';
import { executeOnIntersection } from '../utils/execute-on-intersection';
const SELECTOR = '.js-gallery-slider';

const map = new Map<Element, any>();

function createNavigation(parent: HTMLElement) {
    parent.innerHTML += `
        <button class="gallery-slider__thumbs-ctrl gallery-slider__thumbs-ctrl--prev js-slide-prev" aria-label="предыдущий слайд">
            <svg width="27" height="10" viewBox="0 0 27 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8845 9.44L7.8445 5.616C7.8125 5.57333 7.7805 5.53067 7.7485 5.488C7.70583 5.44533 7.6685 5.40267 7.6365 5.36H26.9325V4.08H7.6365C7.67917 4.02667 7.72183 3.97333 7.7645 3.92C7.80717 3.86667 7.85517 3.81333 7.9085 3.76L10.8845 0H9.1885L5.4925 4.72L9.2045 9.44H10.8845ZM6.0845 9.44L3.0445 5.616C2.84183 5.37067 2.67117 5.17333 2.5325 5.024C2.39383 4.87467 2.29783 4.77867 2.2445 4.736C2.29783 4.68267 2.39917 4.576 2.5485 4.416C2.69783 4.24533 2.8845 4.02667 3.1085 3.76L6.0845 0H4.3885L0.6925 4.72L4.4045 9.44H6.0845Z" fill="#252422"/>
            </svg>
        </button>
        <button class="gallery-slider__thumbs-ctrl gallery-slider__thumbs-ctrl--next js-slide-next" aria-label="следующий слайд">
            <svg width="27" height="10" viewBox="0 0 27 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8845 9.44L7.8445 5.616C7.8125 5.57333 7.7805 5.53067 7.7485 5.488C7.70583 5.44533 7.6685 5.40267 7.6365 5.36H26.9325V4.08H7.6365C7.67917 4.02667 7.72183 3.97333 7.7645 3.92C7.80717 3.86667 7.85517 3.81333 7.9085 3.76L10.8845 0H9.1885L5.4925 4.72L9.2045 9.44H10.8845ZM6.0845 9.44L3.0445 5.616C2.84183 5.37067 2.67117 5.17333 2.5325 5.024C2.39383 4.87467 2.29783 4.77867 2.2445 4.736C2.29783 4.68267 2.39917 4.576 2.5485 4.416C2.69783 4.24533 2.8845 4.02667 3.1085 3.76L6.0845 0H4.3885L0.6925 4.72L4.4045 9.44H6.0845Z" fill="#252422"/>
            </svg>
        </button>
    `;
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>(SELECTOR)).forEach(async (el) => {
        const slides = el.querySelectorAll('.swiper-slide');

        if (slides.length > 1) {
            const { Swiper, Thumbs, FreeMode, EffectCreative, Pagination, Navigation } = await fetchSwiper();
            const parentContainer = el.closest('.js-gallery-slider-container');
            const thumbsSlider = parentContainer?.querySelector('.js-thumbs-slider') as HTMLElement;

            executeOnIntersection(
                el,
                () => {
                    if (thumbsSlider) {
                        const navigation = parentContainer?.querySelector<HTMLElement>('.js-thumbs-slider-navigation');
                        if (navigation) createNavigation(navigation);
                        const prevBtn = navigation?.querySelector<HTMLElement>('.js-slide-prev');
                        const nextBtn = navigation?.querySelector<HTMLElement>('.js-slide-next');

                        const thumbsSwiper = new Swiper(thumbsSlider, {
                            modules: [FreeMode],
                            spaceBetween: 8,
                            slidesPerView: 'auto',
                            freeMode: true,
                            watchSlidesProgress: true,
                            slideActiveClass: 'is-active',
                        });

                        const slider = new Swiper(el, {
                            modules: [Thumbs, EffectCreative, Pagination, Navigation],
                            slidesPerView: 1,
                            spaceBetween: 12,
                            speed: 1600,
                            effect: "creative",
                            creativeEffect: {
                                prev: {
                                    translate: [0, 0, -1],
                                },
                                next: {
                                    translate: ["100%", 0, 0],
                                },
                                limitProgress: 10,
                            },
                            thumbs: {
                                swiper: thumbsSwiper,
                            },
                            pagination: {
                                el: ".js-slider-pagination",
                                bulletClass: 'slider-pagination-item',
                                clickable: true
                            },
                            navigation: {
                                nextEl: nextBtn,
                                prevEl: prevBtn,
                                disabledClass: 'is-disabled'
                            },
                        })

                        map.set(el, slider);
                    }
                },
                { rootMargin: '200px 0px 200px 0px' },
            );
        }
    });
}

const _module = { init };

export default _module;
