import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';
import toggleLitBtn from './inits/toggle-lit-btn';
import menuToggle from './inits/header-menu';
import inputMasks from './inits/masks';
import select from './inits/select';
import multiSelect from './inits/multi-select';
import formSender from './inits/form-sender';
import vhMobileFix from './../modules/vh-mobile-fix';
import 'swiper/scss';
import videoPlay from './inits/videoPlay';
import purchaseSlider from './inits/purchase-slider';
import splittedText from './animation/splitted-text';
import tabs from './inits/tabs';
import layoutTabs from './inits/layout-tabs';
import reveal from './inits/reveal';
import slogan from './inits/slogan';
import maps from './inits/maps';
import likesInit from './inits/likes-card';
import promptModal from './inits/prompt-modal';
import catalogFilters from './inits/catalog-filters';
import tabsDrag from './inits/tabs-drag';
import loader from './animation/loader';
import scrollTo from './inits/scrollTo';
import headerTheme from './inits/header-theme';
import benefitsSlider from './inits/benefits-slider';
import gallerySlider from './inits/gallery-slider';
import similarSlider from './inits/similar-slider';
import advantagesSlider from './inits/advantages-slider';
import catalogSlider from './inits/swiper-catalog';
import housingSlider from './inits/housing-slider';
import catalogValidates from './inits/catalog-validates';
import mapFilters from './inits/map-filters';
import headerLogo from './inits/header-logo';
import { preloader } from './components/preloader';
import { timeout } from './utils/timeout';
import { initCustomElements } from './custom-elements';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import { lazyload } from './inits/lazy-load';
import scrollBarWidth from '../modules/calculate-scrollbar-width';
import calculator from './inits/calculator';
import accordion from './inits/accordion';
import calculatorForm from './inits/calculator-form';
import creditsSlider from './inits/credits-slider';
import processFilters from './inits/process-filters';
import processTabs from './inits/process-tabs';
import mobileSortCopy from './inits/mobile-sort-copy';
import filtersVisible from './inits/filters-visible';
import visualApartmentPicker from './inits/visual-apartment-picker';
import backPage from './inits/back-page';
import promotionsSlider from './inits/promotions-slider';
import infoSlider from './inits/info-slider';
import linkToTabs from './inits/link-to-tabs';

import yandexMapInit from './inits/yandex-map';

document.documentElement.classList.add('js-ready');
document.body.classList.add('no-scroll');

if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
}

applyPolyfills().then(() => {
    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CustomEase);
    initCustomElements();
    toggleLitBtn.init();
    menuToggle.init();
    inputMasks.init();
    select.init();
    multiSelect.init();
    vhMobileFix();
    formSender.init();
    videoPlay.init();
    purchaseSlider.init();
    tabs.init();
    layoutTabs.init();
    promptModal.init();
    lazyload.update();
    catalogFilters.init();
    tabsDrag.init();
    yandexMapInit.init();
    advantagesSlider.init();
    housingSlider.init();
    creditsSlider.init();
    visualApartmentPicker.init();
    scrollBarWidth();
    calculator.init();
    backPage.init();
    linkToTabs.init();

    let timeoutValue: number[] = [];
    if (window.location.pathname == '/' || window.location.pathname == '/html/') {
        timeoutValue = [3000, 1800];
    } else {
        timeoutValue = [800, 1200];
    }

    preloader
        .loadAssets()
        .then(() => {
            loader.init();
        })
        .then(() => timeout(300))
        .then(() => {
            if (!window.location.hash) {
                window.scrollTo({ top: 0, behavior: 'auto' });
            }
        })
        .then(() => timeout(timeoutValue[0]))
        .then(() => {
            splittedText.init();
            document.body.classList.remove('no-scroll');
        })
        .then(() => timeout(timeoutValue[1]))
        .then(() => {
            preloader.leave();
            reveal.init();
            scrollTo.init();
            slogan.init();
            headerTheme.init();
            catalogValidates.init();
            mapFilters.init();
            headerLogo.init();
            accordion.init();
            catalogSlider.init();
            calculatorForm.init();
            benefitsSlider.init();
            gallerySlider.init();
            similarSlider.init();
            maps.init();
            likesInit.init();
            processFilters.init();
            processTabs.init();
            mobileSortCopy.init();
            filtersVisible.init();
            promotionsSlider.init();
            infoSlider.init();
        });
});

// Always at the end
// module.hot?.accept();
