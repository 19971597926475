import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import { setPlaceholder } from './multi-select';

function init() {
    const sortInputCopyElements = document.querySelectorAll<HTMLInputElement>('.js-sort-input-copy');
    const sortInputMainElements = document.querySelectorAll<HTMLInputElement>('.js-sort-input');

    sortInputCopyElements.forEach(inputCopy => {
        const popup = inputCopy.closest<LitPopupElement>('app-lit-popup');

        inputCopy.addEventListener('change', () => {
            const inputValue = inputCopy.value;
            sortInputMainElements.forEach(mainInput => {
                if (mainInput.value === inputValue) {
                    const changeEvent = new MouseEvent('click');
                    mainInput.dispatchEvent(changeEvent);
                }
            })

            popup?.close();
        })
    })

    sortInputMainElements.forEach(input => {
        input.addEventListener('change', () => {
            const inputValue = input.value;
            sortInputCopyElements.forEach(copyInput => {
                if (copyInput.value === inputValue) {
                    copyInput.checked = true;
                    setPlaceholder(copyInput);
                }
            })
        })
    })
}

const _module = { init };

export default _module;
