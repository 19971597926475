/* eslint-disable max-len */
export default function createBankItem(bank: any) {
    const item = document.createElement('li');
    item.classList.add('calculator-banks__item');
    item.innerHTML = `
        <a href="#" class="bank-item js-credit-form-trigger" data-lit-popup-open="feedback-popup" aria-label="Оставить заявку">
            <div class="bank-item__name-block">
                <div class="bank-item__logo">
                    <img src="${bank.logo}" alt="" width="56" height="56">
                </div>
                <div class="bank-item__name">${bank.name}</div>
            </div>
            <div class="bank-item__cell bank-item__cell--rate ${bank.rate.length === 2 ? `btn-prompt` : ''}">
                <span class="catalog-filters__caption text-cap">% ставка</span>
                <div class="input-block__row-item">
                    ${
                        bank.rate.length === 1
                            ? `от ${bank.rate}%`
                            : `
                        ${bank.rate[0]}%
                        <span class="input-block__arrow">
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.65685 12.3136L11.3137 6.65674M11.3137 6.65674L5.65685 0.999884M11.3137 6.65674H0" stroke="black"/>
                            </svg>
                        </span>
                        ${bank.rate[1]}%
                    `
                    }
                </div>
                ${
                    bank.rate.length === 2 && bank.first_payment_term
                        ? `
                    <span class="prompt-modal text-sub">
                        Первые ${bank.first_payment_term} месяцев - ставка ${bank.rate[0]}%
                        <br />
                        На оставшийся срок - ставка ${bank.rate[1]}%
                    </span>
                `
                        : ''
                }
            </div>
            <div class="bank-item__cell bank-item__cell--time">
                <span class="catalog-filters__caption text-cap">Срок кредита</span>
                <div class="input-block__row-item">
                    до ${bank.year} лет
                </div>
            </div>
            <div class="bank-item__cell bank-item__cell--initial-fee">
                <span class="catalog-filters__caption text-cap">Первоначальный взнос</span>
                <div class="input-block__row-item">
                    от ${bank.initialFee}%
                </div>
            </div>
            <div class="bank-item__cell bank-item__cell--monthly-payment ${
                bank.monthyPayment.length === 2 ? `btn-prompt` : ''
            }">
                <span class="catalog-filters__caption text-cap">Платеж в месяц</span>
                <div class="input-block__row-item">
                    ${
                        bank.monthyPayment.length === 1
                            ? bank.monthyPayment[0] + ` ₽`
                            : `
                        ${bank.monthyPayment[0]} ₽
                        <span class="input-block__arrow">
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.65685 12.3136L11.3137 6.65674M11.3137 6.65674L5.65685 0.999884M11.3137 6.65674H0" stroke="black"/>
                            </svg>
                        </span>
                        ${bank.monthyPayment[1]} ₽
                    `
                    }
                </div>
                ${
                    bank.monthyPayment.length === 2 && bank.first_payment_term
                        ? `
                    <span class="prompt-modal text-sub">
                        Первые ${bank.first_payment_term} месяцев - ${bank.monthyPayment[0]}&nbsp;₽
                        <br />
                        На оставшийся срок - ${bank.monthyPayment[1]}&nbsp;₽
                    </span>
                `
                        : ''
                }
            </div>
            <div class="bank-item__info">
                ${
                    !bank.message
                        ? ''
                        : `
                    <span class="btn-prompt js-prompt">
                        <span class="btn-icon">
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.3">
                                    <circle cx="8.5" cy="8.5" r="8" stroke="#252422"/>
                                    <path d="M7.78505 10.2088V8.1624H8.36536C8.77938 8.1624 9.10517 8.04072 9.34272 7.79736C9.58706 7.54663 9.70923 7.21846 9.70923 6.81286C9.70923 6.40726 9.58706 6.08278 9.34272 5.83942C9.10517 5.58869 8.77599 5.46332 8.35518 5.46332H7.02148V4.43457H8.345C8.82689 4.43457 9.24431 4.53413 9.59724 4.73324C9.95018 4.92498 10.2251 5.19784 10.4219 5.55181C10.6187 5.90579 10.7171 6.32614 10.7171 6.81286C10.7171 7.24058 10.6323 7.62406 10.4626 7.96329C10.2997 8.29514 10.0723 8.56063 9.7805 8.75974C9.48865 8.95885 9.14928 9.06947 8.76241 9.09159V10.2088H7.78505ZM8.11084 12.565C7.92758 12.565 7.77487 12.5023 7.6527 12.377C7.53731 12.2442 7.47962 12.0783 7.47962 11.8792C7.47962 11.6874 7.53731 11.5289 7.6527 11.4035C7.77487 11.2708 7.92758 11.2044 8.11084 11.2044H8.41626C8.61988 11.2044 8.77599 11.2671 8.88458 11.3925C8.99996 11.5178 9.05766 11.6801 9.05766 11.8792C9.05766 12.0783 8.99657 12.2442 8.8744 12.377C8.75902 12.5023 8.6063 12.565 8.41626 12.565H8.11084Z" fill="#252422"/>
                                </g>
                            </svg>
                        </span>
                        <span class="prompt-modal text">
                            <span class="prompt-text">
                                ${bank.message}
                            </span>
                        </span>
                    </span>
                `
                }
            </div>
        </a>
    `;
    return item;
}
