import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

let formInner: HTMLElement | null;
let formPopup: LitPopupElement | null | undefined;
let filtersSticky: HTMLElement | null | undefined;

const filtersObserver = new IntersectionObserver((entries, obs) => {
    entries.forEach((entry) => {
        if (window.matchMedia('(min-width: 768px)').matches) {
            if (entry.isIntersecting) {
                if (formPopup) {
                    formPopup.classList.remove('is-popup-fixed');
                }
            } else {
                if (formPopup) {
                    formPopup.classList.add('is-popup-fixed');
                }
            }
        }

        setVisibleStickyFilters(!(entry.isIntersecting));
    });
});

function setPopupInnerHeight() {
    if (!formInner) return;
    const innerHeight = formInner.clientHeight;
    formInner.style.height = `${innerHeight}px`;
}

function setVisibleStickyFilters(show: boolean) {
    if (filtersSticky) {
        show ? filtersSticky.classList.add('is-visible') : filtersSticky.classList.remove('is-visible');
    }
}

function init() {
    formInner = document.querySelector<HTMLInputElement>('.js-catalog-filters-inner');
    filtersSticky = document.querySelector<HTMLInputElement>('.js-filters-sticky');
    formPopup = formInner?.querySelector<LitPopupElement>('app-lit-popup');
    if (!formInner) return;

    setPopupInnerHeight();
    filtersObserver.observe(formInner);

    formPopup?.addEventListener('open', () => setVisibleStickyFilters(false));
    formPopup?.addEventListener('close', () => setVisibleStickyFilters(true));
}

const _module = { init };

export default _module;
