function clickHandler(e: Event) {
    e.preventDefault();
    window.history.back();
}

function init(container: HTMLElement | Document = document) {
    const backLinks = container.querySelectorAll('.js-back');
    backLinks.forEach((el) => el.addEventListener('click', clickHandler));
}

const _module = { init };

export default _module;
