import { fetchSwiper } from '../dynamic-modules';
import { executeOnIntersection } from '../utils/execute-on-intersection';
const SELECTOR = '.js-info-slider';

function createNavigation(parent: HTMLElement) {
    parent.innerHTML = `
        <div class="advantages-section__pagination swiper-pagination button-text js-slider-pagination"></div>
        <div class="advantages-section__navigation slider-ctrls">
            <button class="slider-btn slider-btn--prev js-slide-prev is-disable" aria-label="предыдущий слайд">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.65685 12.3136L11.3137 6.65674M11.3137 6.65674L5.65685 0.999884M11.3137 6.65674H0" stroke="black"/>
                </svg>
            </button>
            <button class="slider-btn slider-btn--next js-slide-next" aria-label="следующий слайд">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.65685 12.3136L11.3137 6.65674M11.3137 6.65674L5.65685 0.999884M11.3137 6.65674H0" stroke="black"/>
                </svg>
            </button>
        </div>
    `;
}

function init(container: HTMLElement | Document = document) {
    let countVisibleSLides = 1;
    if (window.matchMedia('(min-width: 1200px)').matches) {
        countVisibleSLides = 4;
    } else if (window.matchMedia('(min-width: 957px)').matches) {
        countVisibleSLides = 3;
    } else if (window.matchMedia('(min-width: 578px)').matches) {
        countVisibleSLides = 2;
    }

    Array.from(container.querySelectorAll<HTMLElement>(SELECTOR)).forEach(async (el) => {
        const slides = el.querySelectorAll('.swiper-slide');
        const navigation = el.querySelector<HTMLElement>('.js-slider-navigation');

        if (slides.length > 1) {
            const { Swiper, Navigation, Pagination } = await fetchSwiper();

            if (slides.length > countVisibleSLides) {
                if (navigation) createNavigation(navigation);
            }
            const prevBtn = navigation?.querySelector<HTMLElement>('.js-slide-prev');
            const nextBtn = navigation?.querySelector<HTMLElement>('.js-slide-next');
            const pagination = navigation?.querySelector<HTMLElement>('.js-slider-pagination');

            executeOnIntersection(
                el,
                () => {
                    new Swiper(el, {
                        modules: [Navigation, Pagination],
                        slidesPerView: 'auto',
                        spaceBetween: 12,
                        speed: 1000,
                        navigation: {
                            nextEl: nextBtn,
                            prevEl: prevBtn,
                            disabledClass: 'is-disabled',
                        },
                        pagination: {
                            el: pagination,
                            type: 'fraction',
                        },
                        breakpoints: {
                            577: {
                                spaceBetween: 8,
                            },
                            1441: {
                                spaceBetween: 16,
                            },
                        },
                    });
                },
                { rootMargin: '200px 0px 200px 0px' },
            );
        }
    });
}

const _module = { init };

export default _module;
