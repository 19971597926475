function init() {
    const validateInputs = document.querySelectorAll<HTMLInputElement>('.js-input-number');

    validateInputs.forEach(input => {
        input.addEventListener('change', () => {
            const parent = input.closest('.js-input-numbers');
            if (parent) {
                const allInputs = Array.from(
                    parent.querySelectorAll<HTMLInputElement>('.js-input-number')
                ).filter(el => el !== input);
                allInputs.forEach(secondInput => {
                    const inputValue = input.value.replace(/ /g,'');
                    const secondInputValue = secondInput.value.replace(/ /g,'');
                    if (input.classList.contains('is-before')) {
                        if ((Number(inputValue) > Number(secondInputValue)) && secondInput.value != '') {
                            input.value = secondInput.value;
                        }
                    } else {
                        if ((Number(inputValue) < Number(secondInputValue)) && secondInput.value != '') {
                            input.value = secondInput.value;
                        }
                    }
                })
            }
        })
    })
}

const _module = { init };

export default _module;

