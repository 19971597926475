/* eslint-disable max-lines */
import gsap from 'gsap';

let transportTabs: HTMLButtonElement[] = [];
let transportLayers: HTMLElement[] = [];
let transportDesc: HTMLElement[] = [];
let transportMapDescWrapper: HTMLElement | null | undefined;

let environmentTabs: HTMLButtonElement[] = [];
let environmentLayers: HTMLElement[] = [];
let environmentDesc: HTMLElement[] = [];
let environmentMapDescWrapper: HTMLElement | null | undefined;
let environmentMapPhotoBtn: HTMLElement[] = [];

function init(container = document) {
    const mapSection = container.querySelector<HTMLElement>('.js-maps');
    const mapsItem = mapSection?.querySelectorAll<HTMLElement>('.maps-item');
    const frameScroll = container.querySelector<HTMLElement>('.infrastructure-map__layers-scroll');
    const maplayersWrapper = frameScroll?.querySelector<HTMLElement>('.infrastructure-map__layers');

    const frameScrollTransport = container.querySelector<HTMLElement>('.infrastructure-map__layers-scroll--transport');
    const maplayersWrapperTransport = frameScrollTransport?.querySelector<HTMLElement>(
        '.infrastructure-map__layers--transport',
    );

    const frameScrollenvironment = container.querySelector<HTMLElement>(
        '.infrastructure-map__layers-scroll--environment',
    );
    const maplayersWrapperenvironment = frameScrollenvironment?.querySelector<HTMLElement>(
        '.infrastructure-map__layers--environment',
    );

    const mapsContent = container.querySelectorAll<HTMLElement>('.infrastructure-map__left');
    const transportMap = container.querySelector<HTMLElement>('.transport-map');

    const environmentMap = container.querySelector<HTMLElement>('.environment-map');
    const environmentMapMobile = container.querySelector<HTMLElement>('.js-environment-map-mobile');
    const btnsMap = container.querySelectorAll<HTMLElement>('.js-btn-not-default-click');

    btnsMap.forEach((btn) =>
        btn.addEventListener('click', (e) => {
            e.preventDefault();
        }),
    );

    function onResize() {
        if (window.matchMedia('(max-width: 1099px)').matches) {
            transportLayers = Array.from(container.querySelectorAll<HTMLElement>('.js-transport-layer-mob'));
            transportTabs = Array.from(container.querySelectorAll<HTMLButtonElement>('.js-transport-maps__tab-mob'));
            transportDesc = Array.from(container.querySelectorAll<HTMLElement>('.js-transport-desc-mob'));
            transportMapDescWrapper = transportMap?.querySelector<HTMLElement>(
                '.infrastructure-map__descs-wrapper-mob',
            );

            environmentLayers = Array.from(container.querySelectorAll<HTMLElement>('.js-environment-layer-mob'));
            environmentTabs = Array.from(
                container.querySelectorAll<HTMLButtonElement>('.js-environment-maps__tab-mob'),
            );
            environmentDesc = Array.from(container.querySelectorAll<HTMLElement>('.js-environment-desc-mob'));
            environmentMapDescWrapper = environmentMapMobile?.querySelector<HTMLElement>(
                '.js-environment-map__descs-wrapper-mob',
            );
            environmentMapPhotoBtn = Array.from(container.querySelectorAll<HTMLElement>('.js-map-photo-btn-mob'));

            transportTabs.forEach((el) => {
                el.removeEventListener('mouseenter', transportLayerToggle);
                el.removeEventListener('mouseleave', transportLayerRemoveActive);

                el.addEventListener('click', transportLayerToggle);
                window.addEventListener('click', transportLayerBackgroundCLick);
            });

            environmentTabs.forEach((el) => {
                el.removeEventListener('mouseenter', environmentLayerToggle);
                el.removeEventListener('mouseleave', environmentLayerRemoveActive);

                el.addEventListener('click', environmentLayerToggle);
                window.addEventListener('click', environmentLayerBackgroundCLick);
            });
        } else {
            transportLayers = Array.from(container.querySelectorAll<HTMLElement>('.js-transport-layer'));
            transportTabs = Array.from(container.querySelectorAll<HTMLButtonElement>('.js-transport-maps__tab'));
            transportDesc = Array.from(container.querySelectorAll<HTMLElement>('.js-transport-desc'));
            transportMapDescWrapper = transportMap?.querySelector<HTMLElement>(
                '.infrastructure-map__descs-wrapper:not(.alone-text)',
            );

            environmentLayers = Array.from(container.querySelectorAll<HTMLElement>('.js-environment-layer'));
            environmentTabs = Array.from(container.querySelectorAll<HTMLButtonElement>('.js-environment-maps__tab'));
            environmentDesc = Array.from(container.querySelectorAll<HTMLElement>('.js-environment-desc'));
            environmentMapDescWrapper = environmentMap?.querySelector<HTMLElement>(
                '.infrastructure-map__descs-wrapper:not(.alone-text)',
            );
            environmentMapPhotoBtn = Array.from(container.querySelectorAll<HTMLElement>('.js-map-photo-btn'));

            transportTabs.forEach((el) => {
                el.removeEventListener('click', transportLayerToggle);
                el.addEventListener('mouseenter', transportLayerToggle);
                el.addEventListener('mouseleave', transportLayerRemoveActive);
            });
            window.removeEventListener('click', transportLayerBackgroundCLick);

            environmentTabs.forEach((el) => {
                el.removeEventListener('click', environmentLayerToggle);
                el.addEventListener('mouseenter', environmentLayerToggle);
                el.addEventListener('mouseleave', environmentLayerRemoveActive);
            });
            window.removeEventListener('click', environmentLayerBackgroundCLick);
        }

        if (frameScroll && maplayersWrapper) {
            const wrapperWidth = frameScroll.offsetWidth;
            const layersWidth = maplayersWrapper.offsetWidth;

            frameScroll.scrollLeft = (layersWidth - wrapperWidth) / 2;
        }

        if (frameScrollTransport && maplayersWrapperTransport) {
            const wrapperWidth = frameScrollTransport.offsetWidth;
            const layersWidth = maplayersWrapperTransport.offsetWidth;

            const wrapperHeight = frameScrollTransport.offsetHeight;
            const layersHeight = maplayersWrapperTransport.offsetHeight;
            frameScrollTransport.scrollLeft = (layersWidth - wrapperWidth) / 1.5;
            frameScrollTransport.scrollTop = (layersHeight - wrapperHeight) / 2;
        }

        if (frameScrollenvironment && maplayersWrapperenvironment) {
            const wrapperWidth = frameScrollenvironment.offsetWidth;
            const layersWidth = maplayersWrapperenvironment.offsetWidth;

            const wrapperHeight = frameScrollenvironment.offsetHeight;
            const layersHeight = maplayersWrapperenvironment.offsetHeight;
            frameScrollenvironment.scrollLeft = (layersWidth - wrapperWidth) / 1.7;
            frameScrollenvironment.scrollTop = (layersHeight - wrapperHeight) / 2;
        }
    }

    onResize();
    window.addEventListener('resize', onResize);

    function isActive(element: HTMLButtonElement, index: number, array: HTMLButtonElement[]) {
        return element.classList.contains('is-active');
    }

    function transportLayerToggle(this: HTMLButtonElement) {
        if (this.classList.contains('is-active')) return;

        transportTabs.forEach((tab) => {
            tab.classList.remove('is-active');
        });

        transportLayers.forEach((tab) => {
            tab.classList.remove('is-active');
        });

        this.classList.add('is-active');

        const tabActiveId = transportTabs.findIndex(isActive);

        if (transportMapDescWrapper) {
            const tl = gsap.timeline();
            tl.fromTo(
                transportMapDescWrapper,
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    duration: 0.5,

                    onComplete: () => {
                        transportDesc.forEach((desc) => {
                            desc.classList.remove('is-active');
                        });

                        transportDesc[tabActiveId]?.classList.add('is-active');
                    },
                },
            );

            tl.to(transportMapDescWrapper, {
                opacity: 1,
                duration: 0.5,
                onComplete: () => {
                    tl.kill();
                },
            });
        }

        transportLayers[tabActiveId]?.classList.add('is-active');
    }

    function environmentLayerToggle(this: HTMLButtonElement) {
        if (this.classList.contains('is-active')) return;

        environmentLayerRemoveActive();

        if (this.hasAttribute('data-id-icon')) {
            const id = this.getAttribute('data-id-icon')?.split(',');

            id?.forEach((el) => {
                environmentMapPhotoBtn.forEach((btn) => {
                    if (el == btn.getAttribute('data-id-icon')) {
                        btn.classList.remove('is-fade');
                        btn.classList.add('is-active');
                    } else {
                        if (!btn.classList.contains('is-active')) {
                            btn.classList.add('is-fade');
                        }
                    }
                });
            });
        }

        this.classList.add('is-active');

        const tabActiveId = environmentTabs.findIndex(isActive);
        if (environmentMapDescWrapper) {
            const tl = gsap.timeline();

            tl.fromTo(
                environmentMapDescWrapper,
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    duration: 0.5,

                    onComplete: () => {
                        environmentDesc.forEach((desc) => {
                            desc.classList.remove('is-active');
                        });

                        environmentDesc[tabActiveId + 1]?.classList.add('is-active');
                    },
                },
            );

            tl.to(environmentMapDescWrapper, {
                opacity: 1,
                duration: 0.5,
                onComplete: () => {
                    tl.kill();
                },
            });
        }

        environmentLayers[tabActiveId]?.classList.add('is-active');
    }

    function transportLayerBackgroundCLick(e: Event) {
        const clickElement = e.target as HTMLElement;

        if (clickElement && !clickElement.closest('.js-transport-maps__tab-mob')) {
            transportLayers.forEach((layer) => {
                layer.classList.remove('is-active');
            });

            transportTabs.forEach((tab) => {
                tab.classList.remove('is-active');
            });
        }
    }

    function transportLayerRemoveActive() {
        transportLayers.forEach((layer) => {
            layer.classList.remove('is-active');
        });

        transportTabs.forEach((tab) => {
            tab.classList.remove('is-active');
        });
    }

    function environmentLayerRemoveActive() {
        environmentLayers.forEach((layer) => {
            layer.classList.remove('is-active');
        });

        environmentTabs.forEach((tab) => {
            tab.classList.remove('is-active');
        });

        environmentMapPhotoBtn.forEach((btn) => {
            btn.classList.remove('is-fade');
            btn.classList.remove('is-active');
        });
    }

    function environmentLayerBackgroundCLick(e: Event) {
        const clickElement = e.target as HTMLElement;

        if (!clickElement?.closest('.js-environment-maps__tab-mob')) {
            environmentLayers.forEach((layer) => {
                layer.classList.remove('is-active');
            });

            environmentTabs.forEach((tab) => {
                tab.classList.remove('is-active');
            });

            environmentMapPhotoBtn.forEach((btn) => {
                btn.classList.remove('is-fade');
                btn.classList.remove('is-active');
            });
        }
    }

    function mapsChange(mapNumb: number) {
        if (mapsItem) {
            mapsItem[mapNumb].classList.remove('is-next');
            mapsItem[mapNumb].classList.remove('is-prev');
            mapsItem[mapNumb].classList.add('is-active');

            if (mapsItem[mapNumb - 1]) {
                mapsItem[mapNumb - 1].classList.add('is-prev');
                mapsItem[mapNumb - 1].classList.remove('is-active');
            }

            if (mapsItem[mapNumb + 1]) {
                mapsItem[mapNumb + 1].classList.remove('is-active');
                mapsItem[mapNumb + 1].classList.add('is-next');
            }
        }
    }

    let activeTabId = 0;

    if (mapSection) {
        const coverTl = gsap.timeline({
            scrollTrigger: {
                trigger: mapSection,
                start: 'top top',
                end: `bottom bottom`,
                scrub: 0.3,
                onUpdate: (self) => {
                    if (self.progress < 0.33) {
                        if (activeTabId !== 0) {
                            activeTabId = 0;
                            mapsChange(activeTabId);
                        }
                    } else if (self.progress >= 0.33 && self.progress <= 0.66) {
                        if (activeTabId !== 1) {
                            activeTabId = 1;
                            mapsChange(activeTabId);
                        }
                    } else if (self.progress > 0.66) {
                        if (activeTabId !== 2) {
                            activeTabId = 2;
                            mapsChange(activeTabId);
                        }
                    }
                },
            },
        });

        coverTl.fromTo(mapsContent[0], { yPercent: 0 }, { yPercent: -20 }, '0%');
        coverTl.fromTo(mapsContent[1], { yPercent: 0 }, { yPercent: -20 }, '33%');
        coverTl.fromTo(mapsContent[2], { yPercent: 0 }, { yPercent: -20 }, '66%');
    }
}

const _module = { init };

export default _module;
