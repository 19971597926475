import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

let splitted_1: SplitText;
let splitted_2: SplitText;
let splitted_3: SplitText;

function initMainLoader() {
    const firstTitle_1 = document.querySelector('.a-title-1');
    const firstTitle_2 = document.querySelector('.a-title-2');
    const firstTitle_3 = document.querySelector('.a-title-3');

    if (firstTitle_1) {
        splitted_1 = new SplitText(firstTitle_1, {
            type: 'lines',
        });

        splitted_2 = new SplitText(firstTitle_2, {
            type: 'lines',
        });

        splitted_3 = new SplitText(firstTitle_3, {
            type: 'lines',
        });
    }

    const loader = gsap.timeline();
    loader.to('.loader-item', { duration: 1.5, opacity: 1 }, '<');

    loader.to('.loader-icon', { ease: 'power3', rotate: 360, duration: 1 }, 1.5);
    loader.to('.loader-desc', { ease: 'power3', duration: 1, 'clip-path': 'inset(0 0% 0 0)' }, 1.5);

    loader.to('.loader-icon', { ease: 'power3', delay: 0.3, duration: 1, rotate: 0 }, 2.5);
    loader.to('.loader-desc', { ease: 'power3', delay: 0.3, duration: 1, 'clip-path': 'inset(0 100% 0 0)' }, 2.5);
    loader.to('.loader-icon', { ease: 'power3', duration: 0.8, alpha: 0 }, 3.7);
    loader.to('.loader', { delay: 0.2, duration: 2, translateY: '-100%', ease: 'power2' }, '>');
    loader.to('.header__wrapper', { duration: 1.5, translateY: '0%', opacity: 1, ease: 'power2' }, 4.8);
    loader.to('.a-initial-img', { duration: 3, scale: 1.1, ease: 'power1' }, 4.1);
    loader.to('.a-intro-descr', { duration: 1.3, translateY: '0%', opacity: 1, ease: 'power1' }, 5);
    loader.to('.a-intro-info', { duration: 1.3, translateY: '0%', opacity: 1, ease: 'power1' }, 5.1);

    if (splitted_1 && splitted_2 && splitted_3) {
        loader.fromTo(
            splitted_1.lines,
            { yPercent: 100 },
            {
                yPercent: 0,
                duration: 1,
                onComplete: function () {
                    splitted_1.revert();
                },
            },
            5.1,
        );

        loader.fromTo(
            splitted_2.lines,
            { yPercent: 100 },
            {
                yPercent: 0,
                duration: 1,
                onComplete: function () {
                    splitted_2.revert();
                },
            },
            5.1,
        );

        loader.fromTo(
            splitted_3.lines,
            { yPercent: 100 },
            {
                yPercent: 0,
                duration: 1,
                onComplete: function () {
                    splitted_3.revert();
                },
            },
            5.1,
        );
    }
}

function initSimpleLoader () {
    const loader = gsap.timeline();
    loader.to('.loader', { delay: 0.5, duration: 2, translateY: '-100%', ease: 'power2' });
    loader.to('.header__wrapper', { duration: 1.5, translateY: '0%', opacity: 1, ease: 'power2' }, '-=2');
    loader.to('.a-initial-img', { duration: 3, scale: 1.1, ease: 'power1' }, '-=2');
    loader.to('.a-intro-descr', { duration: 1.3, translateY: '0%', opacity: 1, ease: 'power1' }, '-=3');
    loader.to('.a-intro-info', { duration: 1.3, translateY: '0%', opacity: 1, ease: 'power1' }, '-=3');
}

function init() {
    if (window.location.pathname=='/' || window.location.pathname=='/html/') {
        initMainLoader();
    } else {
        initSimpleLoader();
    }
}

const _module = { init };

export default _module;
