import FormSender from '@chipsadesign/form-sender';
import createValidator from '../../modules/validator';

const SELECTOR = '.js-form';
const map = new WeakMap<HTMLFormElement, { sender: Record<string, any>; submitFn: (event: Event) => void }>();

let isSubmitting = false;

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');
    if (checkInput) {
        checkInput.value = '';
    }
};

const showSuccessMessage = (form: HTMLFormElement, message = 'Ваша заявка успешно отправлена') => {
    const successMessage = form.querySelector('.js-form-message__success');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    if (successBlock) {
        successBlock.hidden = false;
    }

    if (failureBlock) {
        failureBlock.hidden = true;
    }

    if (successMessage) {
        successMessage.textContent = message;
    }
};

const showFailureMessage = (form: HTMLFormElement, message = 'Что-то пошло не так. Попробуйте ещё раз.') => {
    const failureMessage = form.querySelector('.js-form-message__failure');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    if (successBlock) {
        successBlock.hidden = true;
    }

    if (failureBlock) {
        failureBlock.hidden = false;
    }

    if (failureMessage) {
        failureMessage.textContent = message;
    }
};

const showFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    messagesContainer?.classList.remove('form-messages--hidden');
};

const hideFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    messagesContainer?.classList.add('form-messages--hidden');

    setTimeout(() => {
        if (successBlock) {
            successBlock.hidden = true;
        }

        if (failureBlock) {
            failureBlock.hidden = true;
        }
    }, 300);
};

const sendCallTrecking = (form: HTMLFormElement) => {
    const formNameValue = form.querySelector('[name="form_name"]')
        ? form.querySelector<HTMLInputElement>('[name="form_name"]')?.value
        : 'form_name';
    const formPageValue = form.querySelector('[name="form_from"]')
        ? form.querySelector<HTMLInputElement>('[name="form_from"]')?.value
        : 'form_from';
    const nameValue = form.querySelector('[name="name"]')
        ? form.querySelector<HTMLInputElement>('[name="name"]')?.value
        : 'name';
    const telValue = form.querySelector('[name="tel"]')
        ? form.querySelector<HTMLInputElement>('[name="tel"]')?.value
        : '';
    const telValueAbbr = telValue ? telValue.replace(/[^\d]/g, '') : '';
    const date = new Date();

    if (window.ctw) {
        window.ctw.createRequest(
            'main_form',
            telValueAbbr,
            [
                { name: 'Name', value: nameValue },
                { name: 'Form name', value: formNameValue },
                { name: 'Page name', value: formPageValue },
            ],
            function (success, data) {
                if (success) {
                } else {
                    switch (data.type) {
                        case 'request_throttle_timeout':
                        case 'request_throttle_count':
                            console.log('Достигнут лимит создания заявок, попробуйте позже');
                            break;
                        case 'request_phone_blacklisted':
                            console.log('номер телефона находится в черном списке');
                            break;
                        case 'validation_error':
                            console.log('были переданы некорректные данные');
                            break;
                        default:
                            console.log('Во время выполнения запроса произошла ошибка: ' + data.type);
                    }
                }
            },
            date.toJSON(),
            [formNameValue],
        );
    }
};

function init() {
    const formList = document.querySelectorAll<HTMLFormElement>(SELECTOR);
    formList.forEach((form) => {
        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });

        const newForm = new FormSender(form, {
            shouldClearInputs: true,
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
            onSuccess: ({ success, message }) => {
                if (success) {
                    showSuccessMessage(form, message);
                    sendCallTrecking(form);
                } else {
                    showFailureMessage(form, message);
                }
            },
            onError: () => {
                showFailureMessage(form, 'Пожалуйста, попробуйте еще раз');
            },
            onComplete: () => {
                showFormMessages(form);
                setTimeout(() => hideFormMessages(form), 5000);
            },
        });

        function submitFn(e: Event) {
            if (isSubmitting) return;
            e.preventDefault();
            const isFormValid = validator.validate(); // метод, которым валидируется форма

            if (isFormValid) {
                form.classList.add('is-load');

                isSubmitting = true;

                newForm.send().finally(() => {
                    isSubmitting = false;

                    form.classList.remove('is-load');
                    let inputs = form.querySelectorAll('.js-form-input');
                    inputs.forEach((el) => {
                        el.classList.remove('is-success');
                    });
                });
            }
        }

        form.addEventListener('submit', submitFn);
        map.set(form, { newForm, submitFn });
    });
}

const _module = { init };

export default _module;
